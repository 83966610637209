import styled from 'styled-components';
import { GoldMemberBadge } from 'app/shared/profile-card/styles';

export const DashboardWrapper = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 100px;
  margin-bottom: 30px;
  min-height: 30vh;
  box-shadow: 0 0 22px 1px rgba(64,67,69,.17);
  background-color: #fff;
  border-radius: 6px;
  justify-content: space-between;
    @media(max-width: 680px) {
      flex-direction: column;
      justify-content: center;
    }
`;

export const MainContent = styled.div`
  display: flex;
  justify-content: space-between;
  width: 64.5%;
  flex-wrap: wrap;
  flex-direction: column;
  @media(max-width: 1000px) {
    width: 50%;
  }
  @media(max-width: 680px) {
    width: 100%;
  }
`;

export const GraphContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  border-radius: 6px;
  flex-wrap: wrap;
  background: #fff;
  padding: 25px 30px 25px 15px;
  margin-bottom: 15px;
  transform: translate3d(0, 0, 0);
  transition: 0.3s;
    @media(max-width: 1070px) {
      padding: 25px;
    }
    @media(max-width: 1000px) {
      flex-direction: column;
      align-content: center;
    }
`;


export const ScoreContentLeft = styled.div`
  width: 50%;
  @media(max-width: 1000px) {
    width: 100%;
  }
`;

export const ScoreContentRight = styled.div`
  width: 50%;
  @media(max-width: 1000px) {
    width: 100%;
  }
`;

export const Score = styled.p`
  position: absolute;
  margin-top: -140px;
  color: #525252;
  z-index: 1;
  font-size: 5em;
  font-weight: 700;
  margin-left: 80px;
  cursor: pointer;
  transition: 0.3s;
`;

export const ScoreUpdate = styled.span`
  position: absolute;
  margin-top: -155px;
  color: ${props => props.scoreChange < 0 ? '#ff0000' : '#3ddb93'};
  z-index: 1;
  font-size: 1em;
  font-weight: 700;
  margin-left: 130px;
`;

export const UpdateNotice = styled.h5`
  margin: 0;
  @media(max-width: 1070px) {
    margin: 25px;
    text-align: center;
  }
`;

export const MiniCardsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  @media (max-width: 1070px) {
    width: 100%;
  }
  
`;

export const SideBarContent = styled.div`
  display: flex;
  justify-content: flex-start;
  width: 33%;
  @media(max-width: 1000px) {
    width: 47.5%;
  }
  @media(max-width: 680px) {
    width: 100%;
  }
`;

export const LeftSidebar = styled.div`
  width: 100%;
  min-height: 560px;
  background: linear-gradient( 135deg,rgb(255,51,101) 0%,rgb(98,58,162) 100%);
  border-radius: 6px;
  border-bottom-right-radius: 0;
  display: flex;
  flex-direction: column;
  @media(max-width: 680px) {
    border-top-right-radius: 6px;
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
    min-height: 360px;
  }
`;

export const GreetingsWrapper = styled.div`
  display: flex;
  justify-content: center;
`;

export const ProfileImageWrapper = styled.div`
  overflow: hidden;
  justify-content: flex-end;
  border-top-left-radius: 6px;
  @media(max-width: 680px) {
    border-top-right-radius: 6px;
  }
`;

export const ProfileImage = styled.img`
  object-fit: contain;
  background-color: white;
  flex-shrink: 0;
  width: 100%;
`;

export const Greetings = styled.div`
  width: 80%;
  margin-top: 1em;
  align-self: baseline;
  @media(max-width: 680px) {
    margin-bottom: ${props => props.addBottomMargin ? '2em' : '0'}
  }
`;

export const AvatarImage = styled.img`
  margin-right: 1.5rem;
  height: 90px;
  margin-top: -0.6rem;
  border-radius: 90px;
  cursor: pointer;
  &:hover {
    filter: blur(1px);
  }
`;

export const SidebarTitle = styled.h3`
  margin-top: -0.2em;
  margin-bottom: 0;
  color: #fff;
  text-align: center;
`;

export const SidebarSubTitle = styled.h4`
  width: 80%;  
  margin: 0.2em auto 0 auto;
  font-weight: 400;
  color: #fff;
  text-align: center;
  opacity: 0.7;
`;

export const BioWrapper = styled.div`
  padding: 0;
  margin-bottom: 10px;
  width: 100%;
`;

export const BadgeWrapper = styled.div`
  display: flex;
  justify-content: flex-start;
`;

export const Title = styled.p`
  font-size: 24px;
  font-weight: 700;
  font-family: 'Avenir';
  color: #333;
  text-transform: capitalize;
  line-height: 0;
  margin-bottom: 30px;
  margin-top: 30px;
`;

export const SubTitle = styled.p`
  font-family: 'Avenir';
  font-size: 16px;
  opacity: 0.6;
  margin-top: -5px;
  margin-bottom: 30px;
  color: #333;
`;

export const SubTitleMissingData = styled.p`
  font-family: 'Avenir';
  font-size: 14px;
  opacity: 1;
  margin-top: 2em;
  font-weight: 600;
  color: #9f6000;
  background-color: #feefb3;
  padding: 10px;
  border-radius: 6px;
  text-align: center;
`;

export const MessageButton = styled.span`
  display: flex;
  margin: ${props => props.loggedInUser ? '0 auto 20px auto' : '10px auto 20px auto'};
  border: none;
  background: ${props => props.loggedInUser ? 'none' : '#fff'};
  border-radius: ${props => props.loggedInUser ? 'none' : '50px'};
  width: ${props => props.loggedInUser ? '' : '70%'};
  cursor: pointer;
  &:hover {
    opacity: 0.9;
  }
  `;

export const MessageText = styled.p`
  font-size: 16px;
  font-weight: 800;
  font-family: 'Avenir';
  color: ${props => props.loggedInUser ? '#fff' : 'linear-gradient( 135deg,rgb(255,51,101) 0%,rgb(98,58,162) 100%)'};
  margin: 15px auto;
  text-decoration: underline;
`;

export const Description = styled.p`
  font-family: 'Avenir';
  font-size: 18px;
  color: #333;
  margin-top: 20px; 
  margin-bottom: 10px;    
`;

export const SpecificsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  margin-top: 10px;
  background-color: #fff;
`;

export const SpecificsInner = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  margin-top: 10px;
  background-color: #fff;
`;

export const SpecificsTitle = styled.p`
  font-size: 16px;
  font-weight: 800;
  font-family: 'Avenir';
  color: #333;
  opacity: 0.6;
`;

export const SpecificsSubTitle = styled.p`
  font-family: 'Avenir';
  font-size: 16px;
  color: #333;
  line-height: 0;
  margin-bottom: 3em;
  font-weight: 100;
  @media(max-width: 680px) {
    line-height: 1.3;
  }
`;

export const FloatingButton = styled.button`
  width: 60px;  
  height: 60px;   
  border-radius: 30px;            
  background-color: #ee6e73;                                    
  position: fixed;                                          
  bottom: 10px;                                                    
  right: 10px;
  align-items: center;
  justify-content: center; 
`;

export const DetailHeader = styled.h4`
  font-weight: 700;
  color: #833895;
  line-height: 0;
  opacity: 0.9;
  font-size: 0.9em;
  margin-bottom: 20px;
`;

export const CustomGoldMemberBadge = styled(GoldMemberBadge)`
  margin-left: 1em;
  align-self: center;
  margin-top: 0.5em;
  padding: 0.3em 0.6em;
`;
