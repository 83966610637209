import React from 'react';

/**
 * Utilities
 */
import {
  MARITAL_STATUS_SELECT_OPTIONS,
  HEIGHT_SELECT_OPTIONS,
  BODY_BUILD_SELECT_OPTIONS,
  CHURCH_ATTENDANCE_SELECT_OPTIONS,
  DRINKING_HABITS_SELECT_OPTIONS,
  SMOKING_HABITS_SELECT_OPTIONS,
  ETHNICITY_SELECT_OPTIONS,
  EDUCATION_SELECT_OPTIONS,
  SEX_SELECT_OPTIONS,
} from 'constants/profile-constants';

/**
 * Component imports
 */
import { SpecificsSubTitle } from './styles';

// /Users/oneuptim/projects/sevndates/app/containers/profile/utilities.js

export const getSelectOption = (name, option) => {
  let selected = [];
  switch (name) {
    case 'HEIGHT_SELECT_OPTIONS':
      selected = HEIGHT_SELECT_OPTIONS.filter(obj => obj.value === Number(option));
      return <SpecificsSubTitle>{selected[0] ? selected[0].label : 'N/A'}</SpecificsSubTitle>;

    case 'BODY_BUILD_SELECT_OPTIONS':
      selected = BODY_BUILD_SELECT_OPTIONS.filter(obj => obj.value === option);
      return <SpecificsSubTitle>{selected[0] ? selected[0].label : 'N/A'}</SpecificsSubTitle>;

    case 'CHURCH_ATTENDANCE_SELECT_OPTIONS':
      selected = CHURCH_ATTENDANCE_SELECT_OPTIONS.filter(obj => obj.value === option);
      return <SpecificsSubTitle>{selected[0] ? selected[0].label : 'N/A'}</SpecificsSubTitle>;

    case 'DRINKING_HABITS_SELECT_OPTIONS':
      selected = DRINKING_HABITS_SELECT_OPTIONS.filter(obj => obj.value === option);
      return <SpecificsSubTitle>{selected[0] ? selected[0].label : 'N/A'}</SpecificsSubTitle>;

    case 'SMOKING_HABITS_SELECT_OPTIONS':
      selected = SMOKING_HABITS_SELECT_OPTIONS.filter(obj => obj.value === option);
      return <SpecificsSubTitle>{selected[0] ? selected[0].label : 'N/A'}</SpecificsSubTitle>;

    case 'ETHNICITY_SELECT_OPTIONS':
      selected = ETHNICITY_SELECT_OPTIONS.filter(obj => obj.value === option);
      return <SpecificsSubTitle>{selected[0] ? selected[0].label : 'N/A'}</SpecificsSubTitle>;

    case 'EDUCATION_SELECT_OPTIONS':
      selected = EDUCATION_SELECT_OPTIONS.filter(obj => obj.value === option);
      return <SpecificsSubTitle>{selected[0] ? selected[0].label : 'N/A'}</SpecificsSubTitle>;

    case 'MARITAL_STATUS_SELECT_OPTIONS':
      selected = MARITAL_STATUS_SELECT_OPTIONS.filter(obj => obj.value === option);
      return <SpecificsSubTitle>{selected[0] ? selected[0].label : 'N/A'}</SpecificsSubTitle>;

    default:
      return <SpecificsSubTitle>N/A</SpecificsSubTitle>;
  }
};

export const getEditSelectOption = (name, option, setFieldValue) => {
  let selected = [];
  switch (name) {
    case 'sex':
      selected = SEX_SELECT_OPTIONS.filter(obj => obj.value === option.value);
      if (!setFieldValue) return selected[0];
      return setFieldValue(name, selected[0]);

    case 'height':
      selected = HEIGHT_SELECT_OPTIONS.filter(obj => obj.value === Number(option.value));
      if (!setFieldValue) return selected[0];
      return setFieldValue(name, selected[0]);

    case 'bodyBuild':
      selected = BODY_BUILD_SELECT_OPTIONS.filter(obj => obj.value === option.value);
      if (!setFieldValue) return selected[0];
      return setFieldValue(name, selected[0]);

    case 'churchAttendance':
      selected = CHURCH_ATTENDANCE_SELECT_OPTIONS.filter(obj => obj.value === option.value);
      if (!setFieldValue) return selected[0];
      return setFieldValue(name, selected[0]);

    case 'drinkingHabits':
      selected = DRINKING_HABITS_SELECT_OPTIONS.filter(obj => obj.value === option.value);
      if (!setFieldValue) return selected[0];
      return setFieldValue(name, selected[0]);

    case 'smokingHabits':
      selected = SMOKING_HABITS_SELECT_OPTIONS.filter(obj => obj.value === option.value);
      if (!setFieldValue) return selected[0];
      return setFieldValue(name, selected[0]);

    case 'ethnicity':
      selected = ETHNICITY_SELECT_OPTIONS.filter(obj => obj.value === option.value);
      if (!setFieldValue) return selected[0];
      return setFieldValue(name, selected[0]);

    case 'education':
      selected = EDUCATION_SELECT_OPTIONS.filter(obj => obj.value === option.value);
      if (!setFieldValue) return selected[0];
      return setFieldValue(name, selected[0]);

    case 'maritalStatus':
      selected = MARITAL_STATUS_SELECT_OPTIONS.filter(obj => obj.value === option.value);
      if (!setFieldValue) return selected[0];
      return setFieldValue(name, selected[0]);

    default:
      return { lable: 'Select one', value: '' };
  }
};

export const mapOptionToValue = (name, value) => {
  const option = { value };
  return getEditSelectOption(name, option, null);
};

export const BUILD_USER_OBJECT = (data) => {
  const userObj = {
    first_name: data.firstName,
    last_name: data.lastName,
    sex: data.sex,
    previously_logged_in: true,
    birthday: data.birthday,
    height: data.height.value,
    education: data.education.value,
    occupation: data.occupation,
    religion: data.religion,
    ethnicity: data.ethnicity.value,
    smoking_habits: data.smokingHabits.value,
    drinking_habits: data.drinkingHabits.value,
    church_attendance_habits: data.churchAttendance.value,
    bio: data.bio,
    body_build: data.bodyBuild.value,
    city: data.city,
    location: data.location,
    state: data.state,
    county: data.county,
    country: data.country,
    zip_code: data.zipCode,
    profile_pic: data.profilePic,
    marital_status: data.maritalStatus.value,
    geo_latitude: Number(data.geoLatitude),
    geo_longitude: Number(data.geoLongitude),
    seeking_penpal: data.seekingPenPal,
    seeking_friends: data.seekingFriends,
    seeking_long_term: data.seekingLongTerm,
    seeking_marriage: data.seekingMarriage,
  };
  return userObj;
};
