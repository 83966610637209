import React from 'react';
import PropTypes from 'prop-types';
import { Wrapper } from '../shared/global-styles';
import DashboardSidebar from './sidebar';
import MainProfileContent from './details';
import ProfileViews from './profile-views/profile-views';
// import GoogleAd from 'utils/google-ads';
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

/**
 * Style imports
 */
import { DashboardWrapper, SideBarContent } from './styles';

const Profile = ({
  user,
  loggedInUserId,
  startNewConversation,
  paramsId,
  profileVisitors,
  uniqueViews,
  photos,
}) => (
  <Wrapper>
    <DashboardWrapper>
      <SideBarContent>
        <DashboardSidebar
          user={user}
          loggedInUserId={loggedInUserId}
          startNewConversation={startNewConversation}
          paramsId={paramsId}
          photos={photos}
        />
      </SideBarContent>
      <MainProfileContent user={user} />
    </DashboardWrapper>
    <ProfileViews profileVisitors={profileVisitors} uniqueViews={uniqueViews} />
    {/* <GoogleAd /> */}
  </Wrapper>
);

Profile.propTypes = {
  user: PropTypes.shape({
    id: PropTypes.string,
  }).isRequired,
  loggedInUserId: PropTypes.string.isRequired,
  startNewConversation: PropTypes.func.isRequired,
  paramsId: PropTypes.string.isRequired,
  profileVisitors: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
    }),
  ).isRequired,
  uniqueViews: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
    }),
  ).isRequired,
};

export default Profile;
