import React from 'react';
import axios from 'axios';
import { CHECK_IS_NEW_MEMBER } from '../../utils/global-helpers';
import { useParams, useNavigate } from 'react-router-dom';
import {
  FormControl,
  FormLabel,
  FormErrorMessage,
  FormHelperText,
  Input,
  Progress,
  Text,
  Radio,
  RadioGroup,
  Stack,
} from '@chakra-ui/react';
import { Formik } from 'formik';
import { geocodeByAddress, getLatLng } from 'react-places-autocomplete';
import PlacesAutocomplete from 'react-places-autocomplete';

/* Constants */
import { BASE_URL } from 'constants/enums';
// import { CHECK_LOGGED_IN_STATUS } from 'app/auth/authentication';

/**
 * Style imports
 */
import { Wrapper, NotificationBar } from '../shared/global-styles';
import {
  SendButton,
  VerificationContainer,
  FormTitle,
  FieldLabel,
  BirthdaySelectWrapper,
  BirthdaySelect,
  GooglePlacesInput,
  LoadingWrapper,
  DropdownContainer,
  SuggestionsItem,
  Error,
  Disclaimer,
} from '../home/styles';

/* Auth */
import { authenticate } from 'app/auth/authentication';

/* Constants */
import {
  BIRTH_YEAR_SELECT_OPTIONS,
  BIRTH_MONTH_SELECT_OPTIONS,
  BIRTH_DATE_SELECT_OPTIONS,
} from 'constants/profile-constants';

/* Utilities */
import { getEditSelectOption, mapOptionToValue } from 'app/profile/profile-utilities';
import { BUILD_REGISTRATION_USER_OBJECT } from 'utils/profile-utils';

const CompleteOnboarding = ({ submitting, toggleForm }) => {
  const navigate = useNavigate();
  const { id } = useParams();

  const [googlePlacesAddress, setGooglePlacesAddress] = React.useState('');
  const [selectedAddress, setSelectedAddress] = React.useState('');
  const [latLng, setLatLng] = React.useState({});
  const [allowEditAddress, setAllowEditAddress] = React.useState(true);
  const [showConfirmButtons, setShowConfirmButtons] = React.useState(false);
  const [errorMsg, setErrorMsg] = React.useState('');
  const [userMeta, setUserMeta] = React.useState({});

  React.useEffect(() => {
    const userMeta = localStorage.getItem('userMeta');
    let user = JSON.parse(userMeta);
    setUserMeta(user);
  }, []);

  const searchOptions = {
    types: ['(cities)'],
  };

  // PUT Profile Update
  const onBuildUserObj = async (userData) => {
    const userObjRes = BUILD_REGISTRATION_USER_OBJECT(userData);

    try {
      const response = await axios.put(`${BASE_URL}/users/${id}`, userObjRes);
      if (response.status === 'unknown') {
        window.location.reload();
      }
      const { data } = response;
      if (!data.success) {
        setErrorMsg(data.err);
        // setSubmitting(false);
      }

      if (response.status === 200 && data.data.id) {
        const { authToken, basic } = data;
        if (authToken && basic) {
          // this.setState({ progressMessage: 'Preparing account...', loginErrorMessage: '' });
          localStorage.setItem('authToken', JSON.stringify(authToken));
          localStorage.setItem('userMeta', JSON.stringify(basic));
          // NAVIGATE TO BROWSE
          navigate(`/browse`);
        }
      }
    } catch (e) {
      window.location.reload();
    }
  };

  const registerUser = async (userObj) => {
    // setSubmitting(true);
    setErrorMsg('');
    // authenticate(userObj);
    const splitAddressComponents = selectedAddress.split(', ');
    userObj.geoLatitude = latLng.lat;
    userObj.geoLongitude = latLng.lng;
    userObj.location = selectedAddress;
    if (splitAddressComponents.length === 2) {
      userObj.city = splitAddressComponents[0];
      userObj.state = null;
      userObj.country = splitAddressComponents[1];
    } else {
      userObj.city = splitAddressComponents[0];
      userObj.state = splitAddressComponents[1];
      userObj.country = splitAddressComponents[2];
    }
    userObj.birthday = `${userObj.birthYear.value}, ${userObj.birthMonth.value}, ${userObj.birthDate.value}`;
    userObj.email = userMeta.email;
    onBuildUserObj(userObj);
    // console.log(latLng, selectedAddress, 'registerUser stuff');
  };

  const handleAddressChange = (googlePlacesAddress) => {
    setGooglePlacesAddress(googlePlacesAddress);
  };

  const handleAddressSelect = async (googlePlacesAddress) => {
    const location = await geocodeByAddress(googlePlacesAddress);
    const loc = location[0];
    const selectedAddress = loc.formatted_address;
    // setAddressComponents(loc.address_components);
    const latLng = await getLatLng(loc);
    setSelectedAddress(selectedAddress);
    setLatLng(latLng);
    setGooglePlacesAddress(selectedAddress);
    setShowConfirmButtons(true);
  };

  // Submit API Call
  const handleSaveLocation = async (user, location) => {
    // const userObj = await BUILD_LOCATION_USER_OBJECT(user, location);
    // try {
    //   await axios.put(`${BASE_URL}/users/${user.id}`, userObj);
    //   this.fetchProfile();
    // } catch (e) {
    //   throw new Error(e);
    // }
  };

  const handleAddressConfirmation = async (action, user) => {
    if (action === 'cancel') {
      setSelectedAddress('');
      setLatLng('');
      setGooglePlacesAddress('');
      setAllowEditAddress(false);
      setShowConfirmButtons(false);
    }
    if (action === 'confirm') {
      const { selectedAddress, latLng } = this.state;
      const location = {
        selectedAddress,
        latLng,
      };
      await handleSaveLocation(user, location);
      setAllowEditAddress(true);
      setGooglePlacesAddress('');
      setShowConfirmButtons(false);
    }
    setAllowEditAddress(false);
    setShowConfirmButtons(false);
  };

  return (
    <>
      <Formik
        initialValues={{
          firstName: userMeta?.firstName,
          lastName: userMeta?.lastName,
          email: userMeta.email,
          birthMonth: '',
          birthDate: '',
          birthYear: '',
          location: selectedAddress,
          sex: 'MALE',
          latLng: latLng,
        }}
        onSubmit={(values) => {
          // registerUser(values);
        }}
      >
        {({ values, setFieldValue, errors, handleChange }) => {
          const { firstName, lastName, email, password, confirmPassword } = values;

          return (
            <VerificationContainer>
              <FormTitle mb={60}>Complete your profile!</FormTitle>
              <Input
                name="firstName"
                placeholder="First Name"
                mb={4}
                onChange={handleChange}
                value={values.firstName}
              />
              <Input
                name="lastName"
                placeholder="Last Name"
                mb={4}
                onChange={handleChange}
                value={values.lastName}
              />

              <RadioGroup defaultValue="MALE" mb={10} mt={6}>
                <Stack spacing={5} direction="row">
                  <Radio
                    colorScheme="blue"
                    name="sex"
                    value="MALE"
                    onChange={() => setFieldValue('sex', 'MALE')}
                  >
                    Male
                  </Radio>
                  <Radio
                    colorScheme="blue"
                    name="sex"
                    value="FEMALE"
                    onChange={() => setFieldValue('sex', 'FEMALE')}
                  >
                    Female
                  </Radio>
                </Stack>
              </RadioGroup>

              <FieldLabel>Birthday</FieldLabel>
              <BirthdaySelectWrapper>
                <BirthdaySelect
                  placeholder="Month"
                  value={values.birthMonth}
                  onChange={(option) => setFieldValue('birthMonth', option)}
                  options={BIRTH_MONTH_SELECT_OPTIONS}
                  name="birthMonth"
                />
                <BirthdaySelect
                  placeholder="Day"
                  value={values.birthDate}
                  onChange={(option) => setFieldValue('birthDate', option)}
                  options={BIRTH_DATE_SELECT_OPTIONS}
                  name="birthDate"
                />
                <BirthdaySelect
                  placeholder="Year"
                  value={values.birthYear}
                  onChange={(option) => setFieldValue('birthYear', option)}
                  options={BIRTH_YEAR_SELECT_OPTIONS()}
                  name="birthYear"
                />
              </BirthdaySelectWrapper>
              <PlacesAutocomplete
                value={googlePlacesAddress}
                onChange={handleAddressChange}
                onSelect={handleAddressSelect}
                searchOptions={searchOptions}
              >
                {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
                  <>
                    <GooglePlacesInput
                      {...getInputProps({
                        placeholder: 'Enter your city (E.g. Los Angeles, CA',
                        className: 'location-search-input',
                      })}
                    />

                    <DropdownContainer>
                      {loading && <LoadingWrapper>Loading...</LoadingWrapper>}
                      {suggestions.map((suggestion, idx) => (
                        <SuggestionsItem
                          {...getSuggestionItemProps(suggestion)}
                          key={suggestion + idx}
                        >
                          <span>{suggestion.description}</span>
                        </SuggestionsItem>
                      ))}
                    </DropdownContainer>
                  </>
                )}
              </PlacesAutocomplete>

              <SendButton type="butsubmitton" onClick={() => registerUser(values)}>
                {submitting ? 'Authenticating...' : 'Update Profile'}
              </SendButton>

              {errorMsg && <Error>{errorMsg}</Error>}

              <Disclaimer textAlign="center">
                By signing up, you confirm that you have read and agreed to our{' '}
                <a href="#">Terms of Use</a> & <a href="#">Privacy Policy</a>.
              </Disclaimer>
            </VerificationContainer>
          );
        }}
      </Formik>
    </>
  );
};

export default CompleteOnboarding;
