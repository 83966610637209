export const CLOUDINARY_OPTIONS = {
  cloudName: 'sevndates',
  uploadPreset: 'sevndates_unsigned',
  sources: ['local', 'camera'],
  buttonCaption: 'Upload Profile Image',
  cropping: true,
  croppingAspectRatio: 1,
  croppingShowBackButton: true,
  minImageWidth: 600,
  theme: 'white',
  multiple: false,
  showPoweredBy: false,
  facebookAppId: '294227791059230',
  singleUploadAutoClose: true,
  autoUpload: false,
  styles: {
    palette: {
      window: '#FFF',
      windowBorder: '#90A0B3',
      tabIcon: '#0E2F5A',
      menuIcons: '#5A616A',
      textDark: '#000000',
      textLight: '#FFFFFF',
      link: '#0078FF',
      action: '#FF620C',
      inactiveTabIcon: '#0E2F5A',
      error: '#F44235',
      inProgress: '#0078FF',
      complete: '#20B832',
      sourceBg: '#E4EBF1',
    },
  },
};

export const BUILD_REGISTRATION_USER_OBJECT = (user) => {
  const userObj = {
    id: user.id,
    facebook_id: user.facebook_id,
    email: user.email,
    password: user.password,
    first_name: user.firstName,
    last_name: user.lastName,
    sex: user.sex ? user.sex : null,
    previously_logged_in: false,
    birthday: new Date(user.birthday) || null,
    height: user.height ? user.height.value : null,
    education: user.education ? user.education.value : null,
    occupation: user.occupation,
    religion: user.religion,
    ethnicity: user.ethnicity ? user.ethnicity.value : null,
    smoking_habits: user.smokingHabits ? user.smokingHabits.value : null,
    drinking_habits: user.drinkingHabits ? user.drinkingHabits.value : null,
    church_attendance_habits: user.churchAttendance ? user.churchAttendance.value : null,
    bio: user.bio,
    body_build: user.bodyBuild ? user.bodyBuild.value : null,
    city: user.city,
    location: user.location,
    state: user.state,
    county: user.county,
    country: user.country,
    zip_code: user.zipCode,
    profile_pic: user.profilePic,
    marital_status: user.maritalStatus ? user.maritalStatus.value : null,
    geo_latitude: Number(user.geoLatitude),
    geo_longitude: Number(user.geoLongitude),
    seeking_penpal: user.seekingPenPal,
    seeking_friends: user.seekingFriends,
    seeking_long_term: user.seekingLongTerm,
    seeking_marriage: user.seekingMarriage,
    oldProfilePic: null,
    verified: true,
  };
  return userObj;
};

export const BUILD_USER_OBJECT = (user) => {
  const userObj = {
    id: user.id,
    facebook_id: user.facebook_id,
    email: user.email,
    first_name: user.firstName,
    last_name: user.lastName,
    sex: user.sex ? user.sex.value : null,
    previously_logged_in: true,
    birthday: new Date(user.birthday) || null,
    height: user.height ? user.height.value : null,
    education: user.education ? user.education.value : null,
    occupation: user.occupation,
    religion: user.religion,
    ethnicity: user.ethnicity ? user.ethnicity.value : null,
    smoking_habits: user.smokingHabits ? user.smokingHabits.value : null,
    drinking_habits: user.drinkingHabits ? user.drinkingHabits.value : null,
    church_attendance_habits: user.churchAttendance ? user.churchAttendance.value : null,
    bio: user.bio,
    body_build: user.bodyBuild ? user.bodyBuild.value : null,
    city: user.city,
    location: user.location,
    state: user.state,
    county: user.county,
    country: user.country,
    zip_code: user.zipCode,
    profile_pic: user.profilePic,
    marital_status: user.maritalStatus ? user.maritalStatus.value : null,
    geo_latitude: Number(user.geoLatitude),
    geo_longitude: Number(user.geoLongitude),
    seeking_penpal: user.seekingPenPal,
    seeking_friends: user.seekingFriends,
    seeking_long_term: user.seekingLongTerm,
    seeking_marriage: user.seekingMarriage,
    oldProfilePic: null,
    verified: user.verified,
  };
  return userObj;
};

export const BUILD_AVATAR_USER_OBJECT = (user, avatar) => {
  const userObj = {
    id: user.id,
    facebook_id: user.facebook_id,
    email: user.email,
    first_name: user.first_name,
    last_name: user.last_name,
    sex: user.sex,
    previously_logged_in: true,
    birthday: new Date(user.birthday),
    height: user.height,
    education: user.education,
    occupation: user.occupation,
    religion: user.religion,
    ethnicity: user.ethnicity,
    smoking_habits: user.smoking_habits,
    drinking_habits: user.drinking_habits,
    church_attendance_habits: user.church_attendance_habits,
    bio: user.bio,
    body_build: user.body_build,
    city: user.city,
    location: user.location,
    state: user.state,
    county: user.county,
    country: user.country,
    zip_code: user.zip_code,
    profile_pic: avatar,
    marital_status: user.marital_status,
    geo_latitude: Number(user.geo_latitude),
    geo_longitude: Number(user.geo_longitude),
    seeking_penpal: user.seeking_penpal,
    seeking_friends: user.seeking_friends,
    seeking_long_term: user.seeking_long_term,
    seeking_marriage: user.seeking_marriage,
    oldProfilePic: user.profile_pic,
    verified: user.verified,
  };
  return userObj;
};

export const BUILD_LOCATION_USER_OBJECT = (user, location) => {
  const userObj = {
    id: user.id,
    facebook_id: user.facebook_id,
    email: user.email,
    first_name: user.firstName,
    last_name: user.lastName,
    sex: user.sex ? user.sex.value : null,
    previously_logged_in: true,
    birthday: new Date(user.birthday),
    height: user.height ? user.height.value : null,
    education: user.education ? user.education.value : null,
    occupation: user.occupation,
    religion: user.religion,
    ethnicity: user.ethnicity ? user.ethnicity.value : null,
    smoking_habits: user.smokingHabits ? user.smokingHabits.value : null,
    drinking_habits: user.drinkingHabits ? user.drinkingHabits.value : null,
    church_attendance_habits: user.churchAttendance ? user.churchAttendance.value : null,
    bio: user.bio,
    body_build: user.bodyBuild ? user.bodyBuild.value : null,
    city: user.city,
    location: location.selectedAddress, // new
    state: user.state,
    county: user.county,
    country: user.country,
    zip_code: user.zipCode,
    profile_pic: user.profilePic,
    marital_status: user.maritalStatus ? user.maritalStatus.value : null,
    geo_latitude: Number(location.latLng.lat), // new
    geo_longitude: Number(location.latLng.lng), // new
    seeking_penpal: user.seekingPenPal,
    seeking_friends: user.seekingFriends,
    seeking_long_term: user.seekingLongTerm,
    seeking_marriage: user.seekingMarriage,
    oldProfilePic: null,
    verified: user.verified,
  };
  return userObj;
};

export const REFRESH_MATCHES_ON_PROFILE_UPDATE = (user) => {
  const basic = JSON.parse(localStorage.getItem('userMeta'));
  const updatedUserMeta = {
    id: basic.id,
    avatar: basic.avatar,
    email: basic.email,
    first_name: basic.first_name,
    last_name: basic.last_name,
    sex: user.sex.value,
    verified: basic.verified,
    latitude: basic.geo_latitude,
    longitude: basic.geo_longitude,
    location: basic.location,
  };
  localStorage.setItem('userMeta', JSON.stringify(updatedUserMeta));
  sessionStorage.removeItem('loadedMatches');
  sessionStorage.removeItem('offsetCount');
};
