import React from 'react';
// import { Link } from "react-router-dom";
import axios from 'axios';
import { BASE_URL } from 'constants/enums';

import Authentication from 'app/auth';
import { LOGOUT } from 'app/auth/authentication';
import { LOGO_CDN_LINK, LOGO_ICON_CDN_LINK } from 'constants/enums';

import {
  GlobalNav,
  Wrapper,
  GlobalNavLayout,
  GlobalNavTabs,
  LogoContainer,
  Logo,
  LogoIcon,
  NavLink,
  GlobalNavUserSection,
  GlobalNavUserSectionInner,
  // LogoWordmark,
  // GlobalNavSearchContainer,
  // LoginButton,
  // SignUpButton,
  // GlobalNavSearch
} from 'app/shared/global-styles';

class Header extends React.PureComponent {
  state = {
    notificationsCount: 0,
    token: null,
    LOGGED_IN_HOME_ROUTE: '',
    loggedInUser: null,
    showMessageCount: false,
  };

  componentDidMount() {
    this.loadUser();
  }

  loadUser = () => {
    const token = localStorage.getItem('authToken');
    const userMeta = localStorage.getItem('userMeta');
    const loggedInUser = JSON.parse(userMeta);
    const LOGGED_IN_HOME_ROUTE = token ? '/browse' : '/';
    if (loggedInUser) {
      this.renderAuthButton(token);
      this.setState(() => ({ token, loggedInUser, LOGGED_IN_HOME_ROUTE }));
      this.fetchNotifications(loggedInUser.id, token);
    }
  };

  fetchNotifications = async (loggedInUserId, token) => {
    const options = {
      withCredentials: false,
      headers: { 'Access-Token': token },
    };
    try {
      const res = await axios.get(`${BASE_URL}/messages/notifications/${loggedInUserId}`, options);
      const notifications = res.data.data;
      const notificationsCount = notifications.length;
      this.setState(() => ({ notificationsCount }));
    } catch (err) {
      console.log(err, 'error');
    }
  };

  renderAuthButton = (token) => {
    const AUTH_BUTTON = token ? (
      <NavLink to="/" onClick={() => LOGOUT()}>
        Logout
      </NavLink>
    ) : (
      <Authentication size="small" />
    );

    return this.setState(() => ({ AUTH_BUTTON }));
  }

  render() {
    const {
      showMessageCount, notificationsCount, token, loggedInUser, LOGGED_IN_HOME_ROUTE, AUTH_BUTTON,
    } = this.state;

    if (!loggedInUser) {
      return (
        <GlobalNav>
          <Wrapper>
            <GlobalNavLayout>
              <GlobalNavTabs>
                {/* <LogoWordmark to="/">Sevn Dates</LogoWordmark> */}
                <LogoContainer to={LOGGED_IN_HOME_ROUTE}>
                  <Logo src={LOGO_CDN_LINK} isLoggedOut={false} />
                  <LogoIcon src={LOGO_ICON_CDN_LINK} isLoggedOut={false} />
                </LogoContainer>
              </GlobalNavTabs>
              <GlobalNavUserSection>
                <GlobalNavUserSectionInner isLoggedOut={false}>
                  <NavLink to="/">Login</NavLink>
                  <NavLink to="/">Register</NavLink>
                </GlobalNavUserSectionInner>
              </GlobalNavUserSection>
            </GlobalNavLayout>
          </Wrapper>
        </GlobalNav>
      )
    }
    return (
      <GlobalNav>
        <Wrapper>
          <GlobalNavLayout>
            <GlobalNavTabs>
              {/* <LogoWordmark to="/">Sevn Dates</LogoWordmark> */}
              <LogoContainer to={LOGGED_IN_HOME_ROUTE}>
                <Logo src={LOGO_CDN_LINK} isLoggedOut={false} />
                <LogoIcon src={LOGO_ICON_CDN_LINK} isLoggedOut={false} />
              </LogoContainer>
            </GlobalNavTabs>
            <GlobalNavUserSection>
              <GlobalNavUserSectionInner isLoggedOut={false}>
                {/* <GlobalNavSearchContainer>
                <GlobalNavSearch placeholder="Search" id="desktopSearchInput" autocorrect="off" />
              </GlobalNavSearchContainer> */}
                {/* <LoginButton to="/login">Log in</LoginButton> */}
                {token && (
                  <>
                    <NavLink to="/browse">Browse</NavLink>
                    <NavLink to={`/profile/${loggedInUser.id}`}>Profile</NavLink>
                    <NavLink
                      to="/messages"
                      onClick={() => this.fetchNotifications(loggedInUser.id, token)}
                    >
                      Messages
                      {' '}
                      {showMessageCount && `(${notificationsCount})`}
                    </NavLink>
                  </>
                )}
                {/* <NavLink to="/settings">Settings</NavLink> */}
                {/* <SignUpButton to="/login">Join</SignUpButton> */}
                {AUTH_BUTTON}
              </GlobalNavUserSectionInner>
            </GlobalNavUserSection>
          </GlobalNavLayout>
        </Wrapper>
      </GlobalNav>
    );
  }
}

export default Header;
