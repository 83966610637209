import React from 'react';
import PlacesAutocomplete from 'react-places-autocomplete';
import PropTypes from 'prop-types';

/**
 * Style imports
 */
import {
  LocationWrapper,
  DropdownContainer,
  LocationTitle,
  Location,
  GooglePlacesInput,
  LoadingWrapper,
  SuggestionsItem,
  Button,
} from './styles';

const searchOptions = {
  types: ['(cities)'],
};

const EditLocation = ({
  googlePlacesAddress,
  handleAddressChange,
  handleAddressSelect,
  selectedAddress,
  handleAddressConfirmation,
  allowEditAddress,
  showConfirmButtons,
  originalLocation,
  values,
}) => (
  <LocationWrapper>
    {selectedAddress && <LocationTitle>You currently live in:</LocationTitle>}
    <Location>{selectedAddress || originalLocation}</Location>
    <PlacesAutocomplete
      value={googlePlacesAddress}
      onChange={handleAddressChange}
      onSelect={handleAddressSelect}
      searchOptions={searchOptions}
    >
      {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
        <>
          {!allowEditAddress && !showConfirmButtons && (
            <GooglePlacesInput
              {...getInputProps({
                placeholder: 'Enter your city (E.g. Los Angeles, CA',
                className: 'location-search-input',
              })}
            />
          )}
          <DropdownContainer>
            {loading && <LoadingWrapper>Loading...</LoadingWrapper>}
            {suggestions.map((suggestion, idx) => (
              <SuggestionsItem {...getSuggestionItemProps(suggestion)} key={suggestion + idx}>
                <span>{suggestion.description}</span>
              </SuggestionsItem>
            ))}
          </DropdownContainer>
        </>
      )}
    </PlacesAutocomplete>
    {showConfirmButtons && (
      <>
        <Button action="confirm" onClick={() => handleAddressConfirmation('confirm', values)}>
          Confirm
        </Button>
        <Button action="cancel" onClick={() => handleAddressConfirmation('cancel', null)}>
          Cancel
        </Button>
      </>
    )}
    {allowEditAddress && (
      <Button action="edit" onClick={() => handleAddressConfirmation('edit', null)}>
        Edit Location
      </Button>
    )}
  </LocationWrapper>
);

EditLocation.propTypes = {
  handleAddressChange: PropTypes.func.isRequired,
  handleAddressSelect: PropTypes.func.isRequired,
  googlePlacesAddress: PropTypes.string.isRequired,
  selectedAddress: PropTypes.string.isRequired,
  handleAddressConfirmation: PropTypes.func.isRequired,
  allowEditAddress: PropTypes.bool.isRequired,
  showConfirmButtons: PropTypes.bool.isRequired,
  originalLocation: PropTypes.string.isRequired,
};

export default EditLocation;
