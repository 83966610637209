import React from 'react';
import axios from 'axios';
import { geocodeByAddress, getLatLng } from 'react-places-autocomplete';
import { useParams } from 'react-router-dom';
import { fireTracking } from 'index';
import { CHECK_LOGGED_IN_STATUS } from 'app/auth/authentication';
import { BASE_URL } from 'constants/enums';

/**
 * Component imports
 */
import ProfileEdit from './profile-edit';

/**
 * Utils imports
 */
import {
  BUILD_USER_OBJECT,
  BUILD_LOCATION_USER_OBJECT,
  REFRESH_MATCHES_ON_PROFILE_UPDATE,
} from 'utils/profile-utils';

function ProfileEditContainer({ match }) {
  const { id } = useParams();
  const [loggedInUserId, setLoggedInUserId] = React.useState(null);
  const [user, setUser] = React.useState(null);
  const [profile, setProfile] = React.useState(null);
  const [googlePlacesAddress, setGooglePlacesAddress] = React.useState('');
  const [selectedAddress, setSelectedAddress] = React.useState('');
  const [latLng, setLatLng] = React.useState({});
  const [allowEditAddress, setAllowEditAddress] = React.useState(true);
  const [showConfirmButtons, setShowConfirmButtons] = React.useState(false);
  const [token, setToken] = React.useState(null);

  const updateLocalStorage = (returnedUser) => {
    const newUserMeta = {
      id: returnedUser.id,
      avatar: returnedUser.profile_pic,
      email: returnedUser.email,
      first_name: returnedUser.first_name,
      last_name: returnedUser.last_name,
      sex: returnedUser.sex,
      latitude: returnedUser.geo_latitude,
      longitude: returnedUser.geo_longitude,
      location: returnedUser.location,
      verified: returnedUser.verified,
    };
    localStorage.setItem('userMeta', JSON.stringify(newUserMeta));
  };

  const fetchProfile = async () => {
    const options = { withCredentials: false, headers: { 'Access-Token': token } };
    try {
      const res = await axios.get(`${BASE_URL}/users/${id}`, options);
      const profile = res.data.data;
      setProfile(profile);
    } catch (e) {
      throw new Error(e);
    }
  };

  const setUpComponent = () => {
    const token = localStorage.getItem('authToken');
    const userMeta = localStorage.getItem('userMeta');
    const parsedUserMeta = JSON.parse(userMeta);
    setLoggedInUserId(parsedUserMeta.id);
    setToken(token);
    setUser(parsedUserMeta);
    fetchProfile();
  };

  React.useEffect(() => {
    CHECK_LOGGED_IN_STATUS();
    setUpComponent();
    fireTracking();
  }, []);

  // PUT Profile Update
  const onProfileUpdate = async (userData) => {
    const userObj = BUILD_USER_OBJECT(userData);
    try {
      await axios.put(`${BASE_URL}/users/${userData.id}`, userObj);

      // Ensure only fires when sex/gender has been updated
      if (user.sex !== userData.sex.value) {
        // Makes sure matches meta matches new gender updated.
        REFRESH_MATCHES_ON_PROFILE_UPDATE(userData);
      }
      fetchProfile();

      localStorage.setItem('isGenderVerified', true);

      window.location.assign(`/profile/${userData.id}`);
    } catch (e) {
      throw new Error(e);
    }
  };

  const handleAddressChange = (googlePlacesAddress) => {
    setGooglePlacesAddress(googlePlacesAddress);
  };

  const handleAddressSelect = async (googlePlacesAddress) => {
    const location = await geocodeByAddress(googlePlacesAddress);
    const loc = location[0];
    const selectedAddress = loc.formatted_address;
    const latLng = await getLatLng(loc);
    setSelectedAddress(selectedAddress);
    setGooglePlacesAddress(selectedAddress);
    setLatLng(latLng);
    setShowConfirmButtons(true);
  };

  const handleAddressConfirmation = async (action, user) => {
    if (action === 'cancel') {
      setSelectedAddress('');
      setGooglePlacesAddress('');
      setAllowEditAddress(false);
      setShowConfirmButtons(false);
    }
    if (action === 'confirm') {
      const location = {
        selectedAddress,
        latLng,
      };
      await handleSaveLocation(user, location);
      setAllowEditAddress(true);
      setGooglePlacesAddress('');
      setShowConfirmButtons(false);
    }
    setAllowEditAddress(false);
    setShowConfirmButtons(false);
  };

  // Submit API Call
  const handleSaveLocation = async (user, location) => {
    const userObj = BUILD_LOCATION_USER_OBJECT(user, location);
    try {
      const res = await axios.put(`${BASE_URL}/users/${user.id}`, userObj);
      const latestProfile = res.data.data;
      setProfile(latestProfile);
      updateLocalStorage(latestProfile);
    } catch (e) {
      throw new Error(e);
    }
  };

  if (!profile) return 'Loading...';
  return (
    <ProfileEdit
      user={profile}
      loggedInUserId={loggedInUserId}
      onProfileUpdate={onProfileUpdate}
      fetchProfile={fetchProfile}
      handleAddressChange={handleAddressChange}
      handleAddressSelect={handleAddressSelect}
      googlePlacesAddress={googlePlacesAddress}
      selectedAddress={selectedAddress}
      handleAddressConfirmation={handleAddressConfirmation}
      allowEditAddress={allowEditAddress}
      showConfirmButtons={showConfirmButtons}
    />
  );
}

export default ProfileEditContainer;
