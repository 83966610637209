import React from 'react'
import axios from 'axios'
import { fireTracking } from 'index'

import { BASE_URL } from 'constants/enums'
import { CHECK_LOGGED_IN_STATUS } from 'app/auth/authentication'
import Conversations from 'app/conversations/conversations'

class ConversationsContainer extends React.PureComponent {
	state = {
		conversations: [],
		messageThread: [],
		loadingConversations: true,
		loadingMessages: false,
		selectedConvo: {},
		firstConversation: null,
		loggedInUser: {},
		token: null,
	}

	componentDidMount() {
		this.getLoggedInUser()
		CHECK_LOGGED_IN_STATUS()
		fireTracking()
	}

	getLoggedInUser = async () => {
		const userMeta = localStorage.getItem('userMeta')
		const token = localStorage.getItem('authToken')
		const loggedInUser = JSON.parse(userMeta)
		await this.setState(() => ({ loggedInUser, token }))
		this.getConversations()
	}

	getConversations = async () => {
		const { loggedInUser, token } = this.state
		const options = {
			withCredentials: false,
			headers: { 'Access-Token': token },
		}
		try {
			const res = await axios.get(
				`${BASE_URL}/conversations/${loggedInUser.id}`,
				options
			)
			const firstConvo = res.data.data.length >= 1 ? res.data.data[0] : null
			if (firstConvo) {
				this.setState(() => ({
					conversations: res.data.data,
					firstConversation: firstConvo,
					loadingConversations: false,
				}))
				this.fetchMessages(firstConvo)
			}
			this.setState(() => ({ loadingConversations: false }))
		} catch (err) {
			console.log(err, 'error')
		}
	}

	fetchMessages = async (convo) => {
		const { loggedInUser } = this.state
		this.setState(() => ({ loadingMessages: true, selectedConvo: convo }))
		const { token } = this.state
		const options = {
			withCredentials: false,
			headers: { 'Access-Token': token },
		}
		try {
			const res = await axios.get(`${BASE_URL}/messages/${convo.id}`, options)
			const messages = res.data.data
			this.setState(() => ({ messageThread: messages }))
			this.setState(() => ({ loadingMessages: false }))

			// Only mark message as READ if recipient is opening
			if (messages[messages.length - 1].sender_id !== loggedInUser.id) {
				this.recordMessageOpen(convo)
			}
		} catch (err) {
			console.log(err, 'error')
		}
	}

	recordMessageOpen = async (convo) => {
		const { loggedInUser } = this.state
		let res
		const messageObj = {
			conversation_id: convo.id,
			sender_id: loggedInUser.id,
			recipient_id: convo.user_id,
			recipient_email: convo.email,
			status: 'READ',
		}
		const token = localStorage.getItem('authToken')
		const options = {
			withCredentials: false,
			headers: { 'Access-Token': token },
		}
		try {
			res = await axios.post(`${BASE_URL}/messages/opened`, messageObj, options)
		} catch (err) {
			console.log(err, 'error')
		}
		return res
	}

	render() {
		const {
			conversations,
			loadingConversations,
			messageThread,
			firstConversation,
			selectedConvo,
			notificationsCount,
			loggedInUser,
		} = this.state

		if (loadingConversations || !messageThread) return 'Loading...'
		return (
			<Conversations
				conversations={conversations}
				fetchMessages={this.fetchMessages}
				getConversations={this.getConversations}
				messageThread={messageThread}
				loggedInUserId={loggedInUser.id}
				firstConversation={firstConversation}
				selectedConvo={selectedConvo}
				notificationsCount={notificationsCount}
			/>
		)
	}
}

export default ConversationsContainer
