// Render Prop
import React from 'react';
import { Formik } from 'formik';
import Select from 'react-select';
import { Toggle } from 'react-formik-ui';
import moment from 'moment';
import { DEFAULT_PROFILE } from 'constants/global-default-data';

/**
 * Component imports
 */
import ImageUploader from './image-uploader';
import EditLocation from 'app/profile-edit/edit-location';

import {
  MARITAL_STATUS_SELECT_OPTIONS,
  HEIGHT_SELECT_OPTIONS,
  BODY_BUILD_SELECT_OPTIONS,
  CHURCH_ATTENDANCE_SELECT_OPTIONS,
  DRINKING_HABITS_SELECT_OPTIONS,
  SMOKING_HABITS_SELECT_OPTIONS,
  ETHNICITY_SELECT_OPTIONS,
  EDUCATION_SELECT_OPTIONS,
  SEX_SELECT_OPTIONS,
} from '../../constants/profile-constants';
import { getEditSelectOption, mapOptionToValue } from '../profile/profile-utilities';

/**
 * Global imports
 */
import { Wrapper } from '../shared/global-styles';
import { SendButton } from '../conversations/styles';
import {
  DashboardWrapper,
  SideBarContent,
  LeftSidebar,
  MainContent,
  ProfileImageWrapper,
  ProfileImage,
} from '../profile/styles';
import {
  CustomForm,
  TextArea,
  FormRow,
  FormItem,
  CustomInputFormWrapper,
  InputFormInner,
  CustomTextInput,
  CustomDatePicker,
  CustomFormItem,
  TogglePair,
  ExceptionWrapper,
} from './styles';

const ProfileEdit = ({
  user,
  onProfileUpdate,
  fetchProfile,
  handleAddressChange,
  handleAddressSelect,
  googlePlacesAddress,
  selectedAddress,
  handleAddressConfirmation,
  allowEditAddress,
  showConfirmButtons,
}) => {
  let transformedBirthday = new Date(user.birthday);
  transformedBirthday = transformedBirthday.setTime(
    transformedBirthday.getTime() + transformedBirthday.getTimezoneOffset() * 60 * 1000,
  );
  return (
    <Wrapper>
      <Formik
        initialValues={{
          id: user.id,
          facebook_id: user.facebook_id,
          bio: user.bio,
          birthday: transformedBirthday,
          occupation: user.occupation,
          profilePic: user.profile_pic,
          sex: mapOptionToValue('sex', user.sex),
          maritalStatus: mapOptionToValue('maritalStatus', user.marital_status),
          churchAttendance: mapOptionToValue('churchAttendance', user.church_attendance_habits),
          ethnicity: mapOptionToValue('ethnicity', user.ethnicity),
          height: mapOptionToValue('height', user.height),
          bodyBuild: mapOptionToValue('bodyBuild', user.body_build),
          education: mapOptionToValue('education', user.education),
          smokingHabits: mapOptionToValue('smokingHabits', user.smoking_habits),
          drinkingHabits: mapOptionToValue('drinkingHabits', user.drinking_habits),
          seekingFriends: user.seeking_friends,
          seekingLongTerm: user.seeking_long_term,
          seekingMarriage: user.seeking_marriage,
          seekingPenPal: user.seeking_penpal,
          firstName: user.first_name,
          lastName: user.last_name,
          email: user.email,
          religion: user.religion,
          city: user.city,
          location: user.location,
          state: user.state,
          county: user.county,
          country: user.country,
          zipCode: user.zip_code,
          geoLatitude: user.geo_latitude,
          geoLongitude: user.geo_longitude,
          previouslyLoggedIn: true,
          verified: user.verified,
        }}
        // validate={(values) => {
        //   const errors = {};
        //   if (!values.email) {
        //     errors.email = 'Required';
        //   } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)) {
        //     errors.email = 'Invalid email address';
        //   }
        //   return errors;
        // }}
        onSubmit={(values, { setSubmitting }) => {
          onProfileUpdate(values);
          // setTimeout(() => {
          //   alert(JSON.stringify(values, null, 2));
          //   setSubmitting(false);
          // }, 400);
        }}
      >
        {({ isSubmitting, values, handleChange, setFieldValue }) => (
          <DashboardWrapper>
            <SideBarContent>
              <LeftSidebar>
                <ProfileImageWrapper>
                  <ProfileImage
                    src={user.profile_pic || user.avatar || DEFAULT_PROFILE.image}
                    alt="profile"
                  />
                  <ImageUploader user={user} fetchProfile={fetchProfile} />
                </ProfileImageWrapper>
                <EditLocation
                  handleAddressChange={handleAddressChange}
                  handleAddressSelect={handleAddressSelect}
                  googlePlacesAddress={googlePlacesAddress}
                  selectedAddress={selectedAddress}
                  handleAddressConfirmation={handleAddressConfirmation}
                  allowEditAddress={allowEditAddress}
                  showConfirmButtons={showConfirmButtons}
                  originalLocation={user.location}
                  values={values}
                />
              </LeftSidebar>
            </SideBarContent>

            <MainContent>
              <CustomForm>
                <TextArea
                  rows="6"
                  cols="50"
                  name="bio"
                  onChange={handleChange}
                  value={values.bio || ''}
                  handleChange={(data) => setFieldValue('bio', data)}
                  placeholder="Write something interesting about yourself..."
                />
                <FormRow>
                  <FormItem>
                    <span>Birthday</span>
                    <CustomInputFormWrapper>
                      <CustomDatePicker
                        name="birthday"
                        dateFormat="MM/dd/YYY"
                        selected={moment(values.birthday).toDate()}
                        onChange={(date) => setFieldValue('birthday', date)}
                        onSelect={(date) => setFieldValue('birthday', date)}
                        placeholderText="E.g. 12/01/1990"
                      />
                    </CustomInputFormWrapper>
                  </FormItem>
                  <FormItem>
                    <span>Gender</span>
                    <Select
                      value={values.sex}
                      onChange={(option) => getEditSelectOption('sex', option, setFieldValue)}
                      options={SEX_SELECT_OPTIONS}
                      name="sex"
                    />
                  </FormItem>
                </FormRow>
                <FormRow>
                  <FormItem>
                    <span>Marital Status</span>
                    <Select
                      value={values.maritalStatus}
                      onChange={(option) =>
                        getEditSelectOption('maritalStatus', option, setFieldValue)
                      } // @option: { value: 'value', label: 'lable };
                      options={MARITAL_STATUS_SELECT_OPTIONS}
                      name="maritalStatus"
                    />
                  </FormItem>
                  <FormItem>
                    <span>Church Attendance</span>
                    <Select
                      value={values.churchAttendance}
                      onChange={(option) =>
                        getEditSelectOption('churchAttendance', option, setFieldValue)
                      }
                      options={CHURCH_ATTENDANCE_SELECT_OPTIONS}
                      name="churchAttendance"
                    />
                  </FormItem>
                </FormRow>
                <FormRow>
                  <FormItem>
                    <span>Education</span>
                    <Select
                      value={values.education}
                      onChange={(option) => getEditSelectOption('education', option, setFieldValue)}
                      options={EDUCATION_SELECT_OPTIONS}
                      name="education"
                    />
                  </FormItem>
                  <FormItem>
                    <span>Occupation</span>
                    <CustomInputFormWrapper>
                      <InputFormInner>
                        <CustomTextInput
                          type="text"
                          name="occupation"
                          value={values.occupation}
                          onChange={handleChange}
                        />
                      </InputFormInner>
                    </CustomInputFormWrapper>
                  </FormItem>
                </FormRow>
                <FormRow>
                  <FormItem>
                    <span>Ethnicity</span>
                    <Select
                      value={values.ethnicity}
                      onChange={(option) => getEditSelectOption('ethnicity', option, setFieldValue)}
                      options={ETHNICITY_SELECT_OPTIONS}
                      name="ethnicity"
                    />
                  </FormItem>
                  <FormItem>
                    <span>Smoking Habits</span>
                    <Select
                      value={values.smokingHabits}
                      onChange={(option) =>
                        getEditSelectOption('smokingHabits', option, setFieldValue)
                      }
                      options={SMOKING_HABITS_SELECT_OPTIONS}
                      name="smokingHabits"
                    />
                  </FormItem>
                </FormRow>
                <FormRow>
                  <FormItem>
                    <span>Height</span>
                    <Select
                      value={values.height}
                      onChange={(option) => getEditSelectOption('height', option, setFieldValue)}
                      options={HEIGHT_SELECT_OPTIONS}
                      name="height"
                    />
                  </FormItem>
                  <FormItem>
                    <span>Drinking Habits</span>
                    <Select
                      value={values.drinkingHabits}
                      onChange={(option) =>
                        getEditSelectOption('drinkingHabits', option, setFieldValue)
                      }
                      options={DRINKING_HABITS_SELECT_OPTIONS}
                      name="drinkingHabits"
                    />
                  </FormItem>
                </FormRow>
                <FormRow>
                  <ExceptionWrapper>
                    <CustomFormItem>
                      <span>Body Type</span>
                      <Select
                        value={values.bodyBuild}
                        onChange={(option) =>
                          getEditSelectOption('bodyBuild', option, setFieldValue)
                        }
                        options={BODY_BUILD_SELECT_OPTIONS}
                        name="bodyBuild"
                      />
                    </CustomFormItem>
                    <CustomFormItem>
                      <span>Email</span>
                      <CustomInputFormWrapper>
                        <InputFormInner>
                          <CustomTextInput
                            type="text"
                            name="email"
                            value={values.email}
                            onChange={handleChange}
                            placeholder="Please enter your email"
                            disabled
                          />
                        </InputFormInner>
                      </CustomInputFormWrapper>
                    </CustomFormItem>
                  </ExceptionWrapper>
                  <FormItem>
                    <span>What are you looking for?</span>
                    <TogglePair>
                      Friends
                      <Toggle name="seekingFriends" />
                    </TogglePair>
                    <TogglePair>
                      Long-Term
                      <Toggle name="seekingLongTerm" />
                    </TogglePair>
                    <TogglePair>
                      Marriage
                      <Toggle name="seekingMarriage" />
                    </TogglePair>
                    <TogglePair>
                      Pen-Pal
                      <Toggle name="seekingPenPal" />
                    </TogglePair>
                  </FormItem>
                </FormRow>
                <SendButton type="submit" disabled={isSubmitting}>
                  Update profile information
                </SendButton>
              </CustomForm>
            </MainContent>
          </DashboardWrapper>
        )}
      </Formik>
    </Wrapper>
  );
};

export default ProfileEdit;
