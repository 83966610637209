import styled from 'styled-components';

export const CardWrapper = styled.div`
  width: 24%;
  min-height: 400px;
  background: #fff;
  margin-top: 40px;
  border-radius: 6px;
  box-shadow: 0px 3px 8px -4px rgba(0, 0, 0, 0.15);
  transform: translate3d(0, 0, 0);
  -webkit-transform: translate3d(0, 0, 0);
  -webkit-transition: 0.3s;
  transition: 0.3s;
  cursor: pointer;
  &:hover {
    -webkit-transform: translate3d(0, -4px, 0);
    transform: translate3d(0, -4px, 0);
    -webkit-transition-property: opacity, -webkit-transform;
    transition-property: opacity, -webkit-transform;
    transition-property: transform, opacity;
    transition-property: transform, opacity, -webkit-transform;
    -webkit-transition-duration: 0.35s;
    transition-duration: 0.35s;
    -webkit-transition-timing-function: ease-out;
    transition-timing-function: ease-out;
    -webkit-transition-delay: 0;
    transition-delay: 0;
    box-shadow: 0px 12px 40px 2px rgba(0,0,0,0.15);
    transition: all 0.35s;
  }
  @media (max-width: 991px) {
    width: 32.7%;
    margin-top: 20px;
  }

  @media (max-width: 767px) {
    width: 49.6%;
  }

  @media (max-width: 591px) {
    width: 100%;
  }
`;

export const CardHeader = styled.div`
  height: 0;
`;

export const BuyButtonContainer = styled.div`
  height: 38px;
  position: relative;
  overflow: hidden;
  border-top-right-radius: 6px;
`;

export const CardBuyButtonLink = styled.a`
  background: #fd3366;
  display: block;
  right: 0%;
  height: 38px;
  position: absolute;
  color: #fff;
  font-size: 13px;
  font-weight: 500;
  text-decoration: none;
  padding: 0 40px 0 20px;
  -webkit-transition: transform 350ms;
  -webkit-transition: -webkit-transform 350ms;
  transition: -webkit-transform 350ms;
  transition: transform 350ms;
  transition: transform 350ms, -webkit-transform 350ms;
  -webkit-transform: translate(20px, 0);
  transform: translate(20px, 0);
    &:hover {
      background-color:#ff0041b8;
      -webkit-transform: translate(0, 0);
      transform: translate(0, 0);
      > svg {
        display: inline-block;
        margin-left: 0.5em;
        margin-top: 0.92em;
        margin-right: -1em;
        font-size: 0.8rem;
      }
    }
    &:after {
      -webkit-transform: scale(1);
      transform: scale(1);
      display: block;
      opacity: 1;
      -webkit-filter: none;
    }
    > span {
      display: block;
      float: left;
      margin: 9px 0 0;
      font-weight: bold;
    }
    > svg {
      display: none;
    }
`;


export const CardBody = styled.div`
  box-shadow: 0px 1px 1px #f9f9f9;
  display: flex;
  flex-direction: column;
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;
  > a {
    text-decoration: none;
    cursor: pointer;
  }
`;

export const CardImageWrapper = styled.div`
  height: 296px;
  overflow: hidden;
  justify-content: flex-end;
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;
`;

export const CardImage = styled.img`
  object-fit: contain;
  background-color: white;
  flex-shrink: 0;
  width: 100%;
  @media (max-width: 991px) {
    width: 330px;
  }
  @media (max-width: 767px) {
    width: 360px;
  }
  @media (max-width: 591px) {
    width: 100%;
  }
`;

export const CardDetails = styled.div`
  margin: 0 18px 10px;
  > p {
    box-sizing: border-box;
    padding-bottom: 2px;
    width: 260px;
    display: block;
    white-space: nowrap;
    text-overflow: ellipsis;
    min-width: 270px;
    width: 90%;
    overflow: hidden;
    margin-bottom: 0;
    text-transform: capitalize;
  }
  > span {
    font-size: 15px;
    color: #b3b3b3;
    font-weight: 500;
    height: 19px;
    width: 220px;
    display: block;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    text-align: left;
    text-transform: capitalize~important;
  }
`;

export const CardFooter = styled.div`
  display: flex;
  height: 30px;
  margin: 14px 0 0;
  font-size: 1em;
  padding: 0 15px;
  justify-content: space-between;
  color: #b3b3b3;
`;

export const CardFooterLeft = styled.div`
  font-weight: 400;
  > span {
    margin-left: 0.5em;
  }
`;

export const CardFooterRight = styled.div`
  > span {
    margin-left: 0.2em;
    margin-right: 1em;
  }
`;

export const Title = styled.p`
  font-family: Avenir;
  font-size: 18px;
  font-weight: 700;
  color: #333;
  opacity: 0.8;
  text-align: left;
  margin-top: 14px;
  text-transform: capitalize;
  width: 260px;
  display: block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  text-transform: capitalize;
`;

export const SubTitle = styled.p`
  font-family: Avenir;
  font-size: 16px;
  opacity: 0.6;
  margin-top: -1px;
  margin-bottom: 10px;
  color: #333;
  width: 260px;
  display: block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  text-transform: capitalize;
`;

export const TitleContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  flex-direction: row;
  align-items: flex-start;
`;

export const NewMemberBadge = styled.p`
  background: #1dd1a1;
  padding: 2px 5px;
  margin-top: 18px;
  min-width: 66px;
  font-family: Avenir;
  font-size: 10px;
  color: #fff;
  font-weight: 800;
  text-align: center;
  `;

export const GoldMemberBadge = styled.p`
  background: linear-gradient( 120deg,rgb(255,51,101) 0%,rgb(98,58,162) 100%);
  padding: 2px 5px;
  margin-top: 18px;
  min-width: 66px;
  font-family: Avenir;
  font-size: 10px;
  color: #fff;
  font-weight: 800;
  text-align: center;
`;
