import React, { Component } from 'react';
import axios from 'axios';
import { fireTracking } from 'index';
import { useParams } from 'react-router-dom';
import { CHECK_LOGGED_IN_STATUS } from 'app/auth/authentication';
import { BASE_URL } from 'constants/enums';

/**
 * Component imports
 */
import VerifyGender from './verify-gender';

/**
 * Utils imports
 */
import { BUILD_USER_OBJECT, REFRESH_MATCHES_ON_PROFILE_UPDATE } from '../../../utils/profile-utils';

function VerifyGenderContainer() {
  const { id } = useParams();
  const [loggedInUserId, setLoggedInUserId] = React.useState(null);
  const [user, setUser] = React.useState(null);
  const [profile, setProfile] = React.useState(null);
  const [token, setToken] = React.useState(null);

  React.useEffect(() => {
    CHECK_LOGGED_IN_STATUS();
    setUpComponent();
    fireTracking();
  }, []);

  const setUpComponent = () => {
    const token = localStorage.getItem('authToken');
    const userMeta = localStorage.getItem('userMeta');
    const parsedUserMeta = JSON.parse(userMeta);
    setLoggedInUserId(parsedUserMeta.id);
    setToken(token);
    setUser(parsedUserMeta);
    fetchProfile();
  };

  const fetchProfile = async () => {
    const options = { withCredentials: false, headers: { 'Access-Token': token } };
    try {
      const res = await axios.get(`${BASE_URL}/users/${id}`, options);
      const profile = res.data.data;
      setProfile(profile);
    } catch (e) {
      throw new Error(e);
    }
  };

  // PUT Profile Update
  const onGenderUpdate = async (user) => {
    const userObj = BUILD_USER_OBJECT(user);
    try {
      await axios.put(`${BASE_URL}/users/${user.id}`, userObj);
      fetchProfile();
      localStorage.setItem('isGenderVerified', true);

      // Makes sure matches reflect the latest gender update.
      REFRESH_MATCHES_ON_PROFILE_UPDATE(user);
      window.location.assign('/browse');
    } catch (e) {
      throw new Error(e);
    }
  };

  if (!profile) return 'Loading...';
  return (
    <VerifyGender user={profile} loggedInUserId={loggedInUserId} onGenderUpdate={onGenderUpdate} />
  );
}

export default VerifyGenderContainer;
