import React from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom';
import { BASE_URL } from 'constants/enums';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

/**
 * Component imports
 */
import ChatThread from 'app/conversations/chat';

/**
 * Style imports
 */

/**
 * Global Helper imports
 */
import { DEFAULT_PROFILE } from 'constants/global-default-data';
import { FORMAT_DAYS_AGO } from 'utils/global-helpers';
import {
  Title,
  Container,
  ExternalFlexWrapper,
  ConvoListWrapper,
  VerticleFlexWrapper,
  ProfileImage,
  InnerWrapper,
  SubTitle,
  RightChevronContainer,
  TimeAgo,
  ContentWrapper,
  DefaultPageTitle,
  ConvoDetailsWrapper,
  ScrollableConversationSection,
  ScrollableMessageThread,
  CustomTextArea,
  FooterActions,
  SendButton,
  AdPlaceholder,
} from './styles';

class Messages extends React.PureComponent {
  state = {
    chatText: '',
    highlightedConvoId: null,
  };

  componentDidMount() {
    const { firstConversation } = this.props;
    if (firstConversation) this.hightlightConvo(firstConversation.id);
  }

  hightlightConvo = (id) => this.setState(() => ({ highlightedConvoId: id }));

  onChatTextChange = (value) => {
    this.setState(() => ({ chatText: value }));
  };

  sendMessage = async (convo) => {
    const { loggedInUserId, fetchMessages, getConversations } = this.props;
    const { chatText } = this.state;
    const chatObj = {
      status: 'READ',
      sender_id: loggedInUserId,
      content: chatText,
      conversation_id: convo.id,
      senders_fname: convo.first_name,
      senders_lname: convo.last_name,
      recipients_email: convo.email,
      recipient_id: convo.user_id,
    };

    const token = localStorage.getItem('authToken');
    const options = {
      withCredentials: false,
      headers: { 'Access-Token': token },
    };
    try {
      await axios.post(`${BASE_URL}/messages`, chatObj, options);
      fetchMessages(convo);
      getConversations();
      this.setState(() => ({ chatText: '' }));
    } catch (err) {
      console.log(err, 'error');
    }
  };

  render() {
    const {
      conversations,
      fetchMessages,
      selectedConvo,
      messageThread,
      loggedInUserId,
    } = this.props;

    const { highlightedConvoId } = this.state;

    const { chatText } = this.state;
    // const ITEM_WIDTH = window.screen.width;
    if (conversations.length > 0) {
      return (
        <Container>
          <ExternalFlexWrapper>
            <VerticleFlexWrapper>
              <ScrollableConversationSection>
                {conversations.map((x) => {
                  // Format time
                  const daysAgo = FORMAT_DAYS_AGO(x.created_at);
                  const transformedImage =
                    x.profile_pic && x.profile_pic.includes('facebook')
                      ? x.profile_pic.replace(
                          'facebook',
                          'facebook/w_200/q_auto'
                        )
                      : x.profile_pic;
                  return (
                    <ConvoListWrapper
                      onClick={() => {
                        fetchMessages(x);
                        this.hightlightConvo(x.id);
                      }}
                      index={x.id}
                      key={x.profile_pic}
                      currentConvo={x.id === highlightedConvoId}
                    >
                      <Link to={`/profile/${x.user_id}`}>
                        <ProfileImage
                          src={
                            x.profile_pic
                              ? transformedImage
                              : DEFAULT_PROFILE.image
                          }
                        />
                      </Link>
                      <ContentWrapper>
                        <InnerWrapper>
                          <Title>{x ? x.first_name : x.last_name}</Title>
                          <TimeAgo>{daysAgo}</TimeAgo>
                        </InnerWrapper>
                        <SubTitle>
                          {`${x.content.substring(0, 52)}`}
                          {x.content.length >= 52 ? '...' : ''}
                        </SubTitle>
                      </ContentWrapper>
                      <RightChevronContainer>
                        <FontAwesomeIcon
                          icon="clock"
                          color="#ddd"
                          size="sm"
                          style={{ marginTop: -1, fontWeight: 500 }}
                        />
                      </RightChevronContainer>
                    </ConvoListWrapper>
                  );
                })}
              </ScrollableConversationSection>
              <a
                href="https://30streams.com/?source=SEVN_DATES"
                target="_blank"
                rel="noopener noreferrer"
              >
                <AdPlaceholder />
              </a>
            </VerticleFlexWrapper>
            <ConvoDetailsWrapper>
              <ScrollableMessageThread>
                {messageThread ? (
                  <ChatThread
                    messageThread={messageThread}
                    loggedInUserId={loggedInUserId}
                  />
                ) : (
                  'None'
                )}
              </ScrollableMessageThread>

              <footer>
                <CustomTextArea
                  name="chat"
                  value={chatText}
                  placeholder="Type your message"
                  onChange={(e) => this.onChatTextChange(e.target.value)}
                  // ref={node => {this.textarea = node;}}
                />
                <FooterActions>
                  {/* <img src="https://s3-us-west-2.amazonaws.com/s.cdpn.io/1940306/ico_picture.png" alt="" /> */}
                  <SendButton onClick={() => this.sendMessage(selectedConvo)}>
                    Send Message
                  </SendButton>
                </FooterActions>
              </footer>
            </ConvoDetailsWrapper>
          </ExternalFlexWrapper>
        </Container>
      );
    }
    return (
      <Container>
        <DefaultPageTitle>
          Future home to all {'\n'}
          your conversations 💬
        </DefaultPageTitle>
      </Container>
    );
  }
}

export default Messages;
