export const DEFAULT_PROFILE_PIC =
  'https://mir-s3-cdn-cf.behance.net/project_modules/disp/14cb6b84808917.5d68451d7d126.gif';

export const DEFAULT_PROFILE = {
  image: DEFAULT_PROFILE_PIC,
  name: 'Tim, 31',
  compatability: '89%',
  location: '',
  bio: 'Reference this table when designing your app’s interface, your app’s interface, and make sure',
  likes: 345,
};

export const DEFAULT_MATCH = {
  first_name: 'Antlers',
  profile_pic:
    'https://mir-s3-cdn-cf.behance.net/project_modules/disp/14cb6b84808917.5d68451d7d126.gif',
  birthday: '2000-01-01',
  location: 'Deerville, CA, USA',
};
