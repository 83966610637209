import axios from 'axios';
import { BASE_URL } from 'constants/enums';

export const SNITCH_PROFILE_VISITS = async (user, visitor) => {
  const condensedUser = {
    avatar: user.profile_pic,
    email: user.email,
    first_name: user.first_name,
    id: user.id,
    last_name: user.last_name,
    sex: user.sex,
  };
  const data = {
    user: condensedUser,
    visitor,
  };
  const token = localStorage.getItem('authToken');
  const options = {
    withCredentials: false,
    headers: { 'Access-Token': token },
  };
  try {
    await axios.post(`${BASE_URL}/profile/visitor-notification`, data, options);
  } catch (err) {
    throw new Error(err);
  }
};
