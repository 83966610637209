import styled from 'styled-components';
import { Content, Logo, GlobalNav, Wrapper } from 'app/shared/global-styles';
import { Form, Formik, Field } from 'formik';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import Select from 'react-select';

export const CustomGlobalNav = styled(GlobalNav)`
  background: transparent;
  border-bottom: none;
  box-shadow: none;
  position: initial;
  ${'' /* background-color: red; */}
`;

export const SmallCenterWrapper = styled(Wrapper)`
  max-width: 380px;
`;

export const CustomContent = styled(Content)`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  padding: 20px 0;
  background: linear-gradient(135deg, rgba(255, 51, 101, 0.9) 0%, rgba(98, 58, 162, 0.8) 100%),
    url(https://images.unsplash.com/photo-1491582990992-68ec88e070a3?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=2689&q=80);
  background-size: cover;
  background-position: top;
  > img {
    transform: scaleX(-1);
    filter: FlipH;
  }
`;

export const CustomLogo = styled(Logo)`
  padding-top: 120px;
`;

export const Header = styled.h1`
  font-family: brandon-grotesque;
  font-styles: normal;
  color: #fff;
  font-size: 54px;
  line-height: 1.2;
  margin-bottom: 0;
  font-weight: 700;
  margin-bottom: 16px;
  @media (max-width: 444px) {
    font-size: 45px;
  }
  @media (max-width: 360px) {
    font-size: 40px;
  }
  @media (max-width: 360px) {
    font-size: 24px;
  }
`;

export const SubHeader = styled.h3`
  font-weight: 400;
  color: #fff;
  font-family: Avenir;
  width: 78%;
  @media (max-width: 444px) {
    width: 88%;
  }
  @media (max-width: 360px) {
    width: 90%;
    font-size: 1em;
  }
  ${
    '' /* margin-bottom: 0;
  line-height: 0; */
  }
`;

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  min-height: 560px;
  width: auto;
  margin: 0 auto;
  @media (max-width: 980px) {
    flex-direction: column;
    justify-content: center;
  }
  @media (max-width: 360px) {
    justify-content: flex-start;
  }
`;

export const LeftContainer = styled.div`
  display: flex;
  flex-direction: column;
  ${'' /* justify-content: center; */}
  margin-top: 180px;
  width: 100%;
  padding: 20px;
  @media (max-width: 1084px) {
    width: 100%;
  }
  @media (max-width: 980px) {
    width: 80%;
    margin: 0;
  }
  @media (max-width: 892px) {
    width: 96%;
  }
`;

// RIGHT
export const RightContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 60%;
  padding: 20px 40px 20px 20px;
  ${'' /* background-color: green; */}
`;

export const VerificationContainer = styled.div`
  display: flex;
  justify-content: space-between;
  min-width: 420px;
  max-width: 480px;
  flex-direction: column;
  padding: 2em;
  margin: 0 auto;
  flex: 1;
  height: auto;
  background-color: #fff;
  box-shadow: 0 0 22px 1px rgba(64, 67, 69, 0.17);
  border-radius: 6px;
  margin-top: 120px;
  margin-bottom: 10px;
`;

export const LoginFormContainer = styled(VerificationContainer)`
  min-height: 420px;
  margin-top: 54px;
  min-width: auto;
  max-width: 480px;
  background-image: url('https://www.kanatanorthba.com/wp-content/uploads/2023/09/member-directory-pattern-1.svg');
  margin: 20px;
`;

export const SmallContainer = styled.div`
  display: flex;
  max-width: 520px;
  flex-direction: column;
  padding: 2em;
  margin: 0 auto;
  flex: 1;
  height: auto;
  background-color: #fff;
  box-shadow: 0 0 22px 1px rgba(64, 67, 69, 0.17);
  border-radius: 6px;
  margin-top: 120px;
  align-items: center;
`;

export const HeroImage = styled.img`
  width: 400px;
  height: 400px;
  border-radius: 200px;
`;

export const SocialMediaLink = styled.a`
  color: #fff;
  margin-left: 30px;
  &:hover {
    opacity: 0.9;
  }
`;

// Borrowed delete after using
export const CustomForm = styled(Form)`
  display: flex;
  flex-direction: column;
  width: 96%;
  padding: 2em 0;
  font-family: Avenir;
  font-size: 0.9em;
  margin: 0 auto;
  @media (max-width: 768px) {
    width: 90%;
    margin: 0 auto;
  }
`;

export const TextArea = styled.textarea`
  margin-bottom: 2em;
  font-size: 1em;
  padding: 1em;
  font-family: Avenir;
  background-color: hsl(0, 0%, 100%);
  border-color: hsl(0, 0%, 80%);
  border-radius: 4px;
  border-style: solid;
  border-width: 1px;
`;

export const FormRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  @media (max-width: 768px) {
    flex-direction: column;
  }
`;

export const FormItem = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  margin-bottom: 2em;
  > span {
    font-weight: bold;
  }
  @media (max-width: 768px) {
    width: 100%;
  }
`;

export const CustomInputFormWrapper = styled.div`
  align-items: center;
  background-color: hsl(0, 0%, 100%);
  border-color: hsl(0, 0%, 80%);
  border-radius: 4px;
  border-style: solid;
  border-width: 1px;
  cursor: default;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  min-height: 38px;
  outline: 0 !important;
  position: relative;
  transition: all 100ms;
  box-sizing: border-box;
`;

export const InputFormInner = styled.div`
  align-items: center;
  display: flex;
  flex: 1;
  flex-wrap: wrap;
  padding: 2px 0;
  position: relative;
  overflow: hidden;
  box-sizing: border-box;
`;

export const CustomTextInput = styled.input`
  font-size: 14.4px;
  width: 100%;
  padding: 0 8px;
  margin: 0;
  box-sizing: border-box;
  cursor: auto;
  font-family: Avenir;
  border: none;
  height: 32px;
`;

export const CustomDatePicker = styled(DatePicker)`
  font-size: 14.4px;
  width: 365px;
  border: none;
  padding: 0 8px;
  margin: 0;
  box-sizing: border-box;
  cursor: auto;
  font-family: Avenir;
  border: none;
  height: 32px;
  @media (max-width: 768px) {
    width: 344px;
  }
`;

export const ImageUploadBtn = styled.button`
  text-decoration: none;
  text-transform: capitalize;
  font-weight: 500;
  color: #fff;
  vertical-align: top;
  font-family: Avenir;
  background-color: none;
  border: none;
  padding: 15px;
  font-size: 1em;
  width: 100%;
  cursor: pointer;
  border-radius: 4px;
  margin-top: -360px;
  display: block;
  z-index: 1000;
  min-height: 300px;
  > p {
    line-height: 0;
  }
`;

export const CustomFormItem = styled(FormItem)`
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  width: 92%;
`;

export const TogglePair = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-top: 1em;
`;

export const SendButton = styled.button`
  text-decoration: none;
  font-weight: 500;
  color: #fff;
  vertical-align: top;
  font-family: Avenir;
  ${'' /* background-color: #45d18b; */}
  background: linear-gradient( 135deg,rgb(255,51,101) 0%,rgb(98,58,162) 100%);
  border: none;
  padding: 15px;
  font-size: 1em;
  width: 100%;
  cursor: ${(props) => (props.opacity ? 'not-allowed' : 'pointer')};
  border-radius: 50px;
  margin-top: 20px;
  &:hover {
    opacity: ${(props) => (props.opacity ? 0.1 : 0.9)};
  }
  margin-bottom: 20px;
  opacity: ${(props) => (props.opacity ? 0.1 : 1)};
`;

export const DashboardWrapper = styled.div`
  display: flex;
  flex-direction: row;
  min-width: 400px;
  margin-top: 100px;
  margin-bottom: 30px;
  min-height: 30vh;
  box-shadow: 0 0 22px 1px rgba(64, 67, 69, 0.17);
  border-radius: 6px;
  justify-content: space-between;
  @media (max-width: 680px) {
    flex-direction: column;
    justify-content: center;
  }
`;

export const HeaderTitle = styled.h1`
  font-family: Avenir;
  font-size: 2em;
`;

export const FormField = styled(Field)`
  padding: 10px;
  margin-bottom: 20px;
  border-radius: 5px;
  border: none;
  background: #eee;
  height: 30px;
  font-size: 16px;
  font-family: Avenir;
  font-weight: 600;
  color: #2f2f2f;
`;

export const FormTitle = styled.h1`
  margin-top: 20px;
  font-family: 'mrs-eaves', sans-serif;
  font-size: 58px;
  ${'' /* color: #222121; */}
  text-align: ${(props) => props.textAlign};
  line-height: 0;
  margin-bottom: ${(props) => (props.mb ? props.mb : 0)}px;
  color: ${(props) => (props.color ? props.color : '#333')};
  @media (max-width: 414px) {
    font-size: 38px;
    text-align: center;
  }
`;

export const SubTitle = styled.h4`
  margin-top: 10px;
  margin-bottom: 0px;
  font-family: Avenir;
`;

export const RadioWrapper = styled.div`
  display: flex;
  flex-direction: row;
`;

export const RadioLabel = styled.label`
  display: flex;
  flex-direction: row;
  cursor: pointer;
`;

export const RadioLabelText = styled.p`
  margin-top: 8px;
  margin-right: 20px;
  font-weight: 600;
  cursor: pointer;
`;

export const FieldLabel = styled.p`
  font-size: 16px;
  font-family: Avenir;
  font-weight: 600;
  color: #2f2f2f;
  line-height: 0;
`;

export const BirthdaySelectWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 15px;
  margin-bottom: 30px;
`;

export const BirthdaySelect = styled(Select)`
  width: 30%;
`;

export const GooglePlacesInput = styled.input`
  font-size: 16.4px;
  width: 100%;
  padding: 0 12px;
  margin: 0em auto 0em auto;
  box-sizing: border-box;
  cursor: auto;
  font-family: Avenir;
  ${'' /* font-weight: 400; */}
  border: none;
  height: 44px;
  background-color: #eee;
  @media (max-width: 414px) {
    margin: 1em auto 0 auto;
  }
`;

export const DropdownContainer = styled.div`
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  border-radius: 0 0 2px 2px;
  width: 100%;
  margin: 0 auto;
  cursor: pointer;
  position: relative;
`;

export const LoadingWrapper = styled.div`
  width: 100%;
  background-color: #fafafa;
  margin: 0 auto;
`;

export const SuggestionsItem = styled.div`
  padding: 5px;
  font-size: 0.9em;
  font-weight: 700;
  font-family: avenir;
  background-color: #fafafa;
  &:hover {
    background-color: #fff;
  }
`;

export const Error = styled.div`
  color: #ff0000;
`;

export const Disclaimer = styled.p`
  font-size: 12px;
  color: #555;
  & a {
    color: #555;
    font-weight: bold;
  }
  text-align: ${(props) => props.textAlign};
  width: 80%;
  margin: 0 auto;
`;

export const Success = styled.p`
  color: #53b853;
  font-size: 16px;
`;
