import React from 'react';

import Header from '../header';
import { ContentWrapper, HeaderSubTitle } from '../shared/global-styles';

const TermsOfService = () => (
  <>
    <Header />
    <ContentWrapper>
      <HeaderSubTitle>Terms of Use</HeaderSubTitle>
      <p>
        Your use and interactions with the Sevn Dates website and app are subject to the following
        terms and conditions. These terms and conditions are subject to change at any time so we
        recommend checking back for the most up to date version.
      </p>

      <ul>
        <li>
          We do not perform any criminal background checks or screen users in any way. As such we
          recommend you use extreme caution when sharing any personal information with other members
          you meet on Sevn Dates. We recommend using a fake name on the site and never share any of
          your personally identifiable information. Never under any circumstances send money to
          another member for any reason and please report any members that act suspiciously. If you
          plan to meet or communicate with any member from Sevn Dates be sure to do so in a public
          place and bring a friend. We are not responsible for the actions of any person you may
          meet from Sevn Dates.
        </li>
        <li>
          To protect our members and provide a safe environment we are able to refuse access or
          moderate content at our discretion. We may moderate any content on the site including but
          not limited to profile text, photos, usernames as well as denying access for any reason.
        </li>

        <li>
          You are legally responsible for any content that you post on our site. Do not post any
          illegal content or content that may be offensive to others or content that is not wholly
          your creation.
        </li>

        <li>
          Access to Sevn Dates is limited to members aged 18 and above. If we suspect you may be
          under 18 your account will be deleted without warning.
        </li>

        <li>
          We are not responsible for any losses or damages that arise from your use of Sevn Dates
        </li>

        <li>
          For more information on how we use your data to create an amazing experience then please
          visit our Privacy Policy.
        </li>
      </ul>
      <p>
        Sevn Dates is a social website and app allowing you to meet new people as well as share
        photos and information.
      </p>

      <p>
        These Terms apply whenever accessing or using Sevn Dates irrespective of if you have a
        membership with us or not you are accepting and agreeing to be bound by these Terms.
      </p>

      <strong>
        SHOULD YOU NOT ACCEPT AND AGREE TO THESE TERMS THEN YOU MUST NOT ACCESS OR USE THE WEBSITE
        OR APP.
      </strong>

      <HeaderSubTitle>Security</HeaderSubTitle>

      <p>
        While your password is not stored in plain text anywhere on our servers the responsibility
        of you to keep your password secure from others is yours alone. If your email account is
        compromised it is likely that an attacker will also be able to access your account at Sevn
        Dates. Should your email account be compromised it is advised to update your Sevn Dates
        account to a new email address and change your password immediately to protect your account.
      </p>

      <p>
        Our emails contain one-click login links and making these emails available to others will
        compromise access to your account. Please treat your emails from Sevn Dates as confidential.
      </p>

      <p>
        We use reputable hosting facilities, firewalls, mandatory encrypted communications using
        TLS, and our best efforts to keep any information/content you submit secure. Additionally
        profiles on this website are not made available to non-members or search engines. However on
        the Internet there is no such thing as perfect security and anything you share has some risk
        of being accessed by a user or non-user of the site that you did not intend to share such
        content with. Anything you share on this website or app you should be prepared for it to be
        public.
      </p>

      <p>
        During the use of our site and app you may find links to other websites or services. We do
        not check or guarantee the safety of such links and you should treat them with extreme
        caution. We have no control over content accessed via outside links. Should you be
        suspicious someone is spamming or scamming please report them so we can block their profile
        and protect other people.
      </p>

      <HeaderSubTitle>Safety</HeaderSubTitle>
      <p>
        You agree that you will take sufficient precautions when communicating with or meeting
        anyone on Sevn Dates. You should not give out any personal details including your real name,
        email address, exact location, or any information which otherwise might make it easy for
        someone to find you in the real world.
      </p>
      <p>
        You agree that you will never send money to any contact that you met on Sevn Dates for any
        reason. We are not responsible for any losses you incur as a result of using this website.
      </p>

      <HeaderSubTitle>Your use of Sevn Dates</HeaderSubTitle>
      <p>
        You warrant that use of Sevn Dates and app is legal in the location that you are using it
        and that your use will not violate any local and regional laws. You are soley responsible
        for compliance with local and regional laws at all times when using or accessing Sevn Dates.
      </p>
      <p>
        Use of Sevn Dates is restricted to personal use only. You may not use this website to
        promote any commercial products, services or events.
      </p>
      <p>
        Using Sevn Dates you agree to conduct yourself in a civil manner and not be a disturbance to
        other members. At our sole discretion we may limit or terminate access to our website and
        app as well as moderate or modify content submitted to our service. We may do this at any
        time and without warning.
      </p>
      <p>
        You agree to submit only content that you own to our website. You may not post photos or
        content that does not belong to you. In an effort to create a genuine environment we will
        share your content with moderators who may temporarily access your photos to determine if it
        follows our guidelines. More information about this is available in our Privacy Policy.
      </p>
      <p>
        You must not use the website in a way that is harmful or a disturbance to others. This
        includes but is not limited to posting photos not of yourself, harrassing or defamatory
        messages, rude or aggressive content, politically, racially or religiously sensitive
        content, content that violates local or national laws.
      </p>
      <p>
        You agree that you may create and use only one profile that is genuinely representative of
        yourself only. Any member creating duplicate or fake profiles risks having all profiles
        blocked permanently without notice. Any paid services will not be refunded in this case.
      </p>

      <HeaderSubTitle>Content Policy</HeaderSubTitle>
      <p>Transmitting or linking to the following content is strictly prohibited:</p>

      <ul>
        <li>Content that depicts any person under the age of 18</li>

        <li>Content that is racially, politically, or religiously sensitive</li>

        <li>
          Content that contains nudity, obscenity, or is pornographic including drawn content and
          text
        </li>

        <li>Defamatory content</li>

        <li>Photos that depict weapons, drug use or gambling</li>

        <li>Commercial content such as advertising of products, services and events</li>

        <li>Any malware, spyware, or virus</li>

        <li>Content that infringes a third parties rights or intellectual property</li>

        <li>Contact details of someone that is not yourself</li>

        <li>Offensive content or content intended to harass</li>
      </ul>
      <HeaderSubTitle>Emails and Notifications</HeaderSubTitle>
      <p>
        By opening an account with us you agree and give us permission to contact you via email or
        other notifications. You can edit your notification settings on the account page at any time
        to cease these notifications. If you opt-out of email communication we may still send
        important account security emails such as forgot password emails or otherwise in order to
        protect the security and privacy of your account.
      </p>

      <HeaderSubTitle>Free Services</HeaderSubTitle>
      <p>
        Our free services are subject to change or limitation at any time. It is in our interest to
        provide a good basic level of service on the site however and we intend for the site to be
        usable in a free state forever.
      </p>

      <HeaderSubTitle>Paid Services</HeaderSubTitle>
      <p>For more information on our paid services you can see our Refund Policy.</p>

      <p>
        In the event that we block, suspend, or moderate your profile or content you are not
        entitled to any refund of any services. Paying for any services does not entitle you to act
        innapropriately and your use of Sevn Dates and app is still governed by these terms and
        conditions.
      </p>

      <p>
        Should we change any service that negatively impacts your experience and you have made a
        payment with time still remaining you may contact us for a pro-rata refund after specifying
        what part of the service that has changed you are not happy with.
      </p>
      <p>
        We do not store your credit card number and use third party billing agents to ensure your
        payment details are secure
      </p>

      <HeaderSubTitle>Intellectual Property</HeaderSubTitle>
      <p>
        You warrant and represent that the information and content posted to your profile is posted
        by yourself only and that you are the sole creator of your profile. For any content provided
        by yourself such as photos or profile text you grant Sevn Dates a non-exclusive, royalty
        free, perpetual, worldwide licence to disseminate, use, process, translate or modify this
        content either on our site or app or in connection with the promotion of our site or app.
        Your photos may be resized, watermarked and edited to improve the experience on the site and
        app.
      </p>

      <p>
        Should you object to any content on our site or app that you believe you are the copyright
        holder of please contact us with a direct link to such content, a screenshot of the content,
        along with a written statement that you are the owner of such content as well as a physical
        address and contact number. Without a direct link to the content it is not possible to
        remove it as there may be millions of profiles and it is not possible to manually look
        through each one.
      </p>

      <p>
        The logos, images, photographs, animations, videos and text appearing on our website and app
        are the intellectual property of Oxixo Private Ltd a company registered in Singapore and
        cannot be reproduced, used, published, distributed, sold or displayed without the express
        prior written permission of us.
      </p>

      <HeaderSubTitle>Disclaimers</HeaderSubTitle>
      <p>
        Content on this website is provided almost entirely by users. While we moderate and respond
        to complaints there may still be content which you may find objectionable or offensive and
        should you be offended by such content you should not use this website or app.
      </p>

      <p>
        We do not perform any criminal background checks or screen users in any way. We are not a
        marriage broker or dating introductions agency and make no recommendation of the validity or
        suitability of our members.
      </p>

      <p>
        Use of this website and app is provided on an "as is" basis without any kind of warrant or
        guarantee of level of service. No user will be entitled to any claim for any damages or
        losses that come from using Sevn Dates or app. This includes but is not limited to mobile
        data service charges, physical harm to your person or items or business or reputation, and
        anything else.
      </p>

      <p>
        You agree to indemnify and hold Sevn Dates, its subsidiaries, affiliates, officers, agents,
        directors, and other partners and employees, harmless from any loss, liability, claim, or
        demand, including reasonable attorneys fees, made by any third party due to or arising out
        of your use of the Service in violation of this Agreement and/or arising from a breach of
        this Agreement and/or any breach of your representations and warranties set forth above.
      </p>

      <HeaderSubTitle>Termination</HeaderSubTitle>
      <p>
        You may close your account temporarily or terminate your account permanently at any time by
        visiting the account page. After termination your profile will be immediately unavailable
        but traces of it may exist in off site backups and logs for up to a month.
      </p>

      <HeaderSubTitle>Abuse</HeaderSubTitle>
      <p>Please report any violations of these terms, abuse or spam to us on our help page.</p>
    </ContentWrapper>
  </>
);

export default TermsOfService;
