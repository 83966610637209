import * as React from 'react';
import {
  Routes,
  Route,
  // Navigate,
} from 'react-router-dom';
import ReactDOM from 'react-dom/client';
import { ChakraProvider } from '@chakra-ui/react';

/**
 * Component imports
 */
import Header from './app/header';
import { Content } from './app/shared/global-styles';
import Home from './app/home';
import VerifyAccount from './app/home/verifyAccount';
import ConfirmEmail from './app/home/confirmEmail';
import ConfirmEmailMobile from './app/home/confirmEmailMobile'; // Mobile Deeplink
import CompleteOnboarding from './app/onboarding/completeOnboarding';
// import ResetPassword from './app/home/resetPassword';
import Browse from './app/browse/browse-container';
import Profile from './app/profile';
import ProfileEdit from './app/profile-edit';
import About from './app/about';
import Conversations from './app/conversations';
import Timeline from './app/timeline';
import VerifyGender from './app/profile-edit/verify-gender';
import TermsOfService from './app/tos';

// Contact
// Settings

const App = () => {
  const [isLoggedIn, setIsLoggedIn] = React.useState(false);

  React.useEffect(() => {
    const loggedIn = localStorage.getItem('authToken');
    if (loggedIn) {
      setIsLoggedIn(true);
    }
  }, []);

  return (
    <ChakraProvider>
      <Content>
        {isLoggedIn && <Header />}
        {/* <Route
				exact
				path="/"
				render={() =>
					loggedIn ? (
						<Navigate to="/browse" />
					) : (
						<Route path="/" element={<Home />} exact></Route>
					)
				}
			/> */}
        <Routes>
          <Route exact path="/" element={<Home />} />
          <Route path="/timeline" element={<Timeline />} />
          <Route exact path="/verify-account/:email" element={<VerifyAccount />} />
          <Route exact path="/confirm-email/:token" element={<ConfirmEmail />} />
          <Route exact path="/deeplink/confirm-email-mobile/:token" element={<ConfirmEmailMobile />} />
          <Route exact path="/complete-onboarding/:id" element={<CompleteOnboarding />} />
          <Route path="/browse" element={<Browse />} />
          <Route exact path="/profile/:id" element={<Profile />} />
          <Route path="/profile/edit/:id" element={<ProfileEdit />} />
          <Route path="/verify-gender/:id" element={<VerifyGender />} />
          <Route path="/messages" element={<Conversations />} />
          <Route path="/about" element={<About />} />
          <Route path="/terms" element={<TermsOfService />} />
        </Routes>
      </Content>
    </ChakraProvider>
  );
};

export default App;
