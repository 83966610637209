import React, { Component } from 'react';
import axios from 'axios';
import PropTypes from 'prop-types';
// import { Image, Video, Transformation, CloudinaryContext } from 'cloudinary-react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// const publicId = 'CLOUDINARY_URL=cloudinary://419635973647785:h0dZUYquIhudQbQlRzQuscqiFNw@sevndates';
import { BASE_URL } from 'constants/enums';

/**
 * Utils imports
 */
import { BUILD_AVATAR_USER_OBJECT, CLOUDINARY_OPTIONS } from '../../utils/profile-utils';

/**
 * Styles imports
 */
import { ImageUploadBtn } from './styles';

class ImageUploader extends Component {
  onCloudinaryWidgetClick = async () => {
    let avatar;
    await window.cloudinary.openUploadWidget(CLOUDINARY_OPTIONS, (error, result) => {
      if (result && result.event === 'success') {
        avatar = result.info.secure_url;
        this.updatedProfilePic(avatar);
      }
    });
  };

  // PUT Profile Update
  updatedProfilePic = async (avatar) => {
    const { user, fetchProfile } = this.props;
    const userObj = BUILD_AVATAR_USER_OBJECT(user, avatar);
    try {
      const res = await axios.put(`${BASE_URL}/users/${user.id}`, userObj);
      const basic = {
        id: user.id,
        email: userObj.email,
        facebook_id: userObj.facebook_id,
        first_name: userObj.first_name,
        last_name: userObj.last_name,
        sex: userObj.sex,
        avatar: userObj.profile_pic,
        latitude: userObj.geo_latitude,
        longitude: userObj.geo_longitude,
        location: userObj.location,
        verified: userObj.verified,
      };
      localStorage.setItem('userMeta', JSON.stringify(basic));
      fetchProfile();
    } catch (e) {
      throw new Error(e);
    }
  };

  render() {
    return (
      <>
        <ImageUploadBtn type="button" onClick={this.onCloudinaryWidgetClick}>
          <FontAwesomeIcon
            icon="camera"
            color="#333"
            size="4x"
            style={{
              marginTop: -2,
              marginRight: 10,
              fontWeight: 500,
              opacity: 0.6,
            }}
          />
          <p>Update Avatar</p>
        </ImageUploadBtn>
      </>
    );
  }
}

ImageUploader.propTypes = {
  fetchProfile: PropTypes.func.isRequired,
};

export default ImageUploader;
