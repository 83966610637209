import { Form } from 'formik';
import styled from 'styled-components';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

export const CustomForm = styled(Form)`
  display: flex;
  flex-direction: column;
  width: 96%;
  padding: 2em 0;
  font-family: Avenir;
  font-size: 0.9em;
  margin: 0 auto;
  @media(max-width: 768px){
    width: 90%;
    margin: 0 auto;
  }
`;

export const TextArea = styled.textarea`
  margin-bottom: 2em;
  font-size: 1em;
  padding: 1em;
  font-family: Avenir;
  background-color: hsl(0,0%,100%);
  border-color: hsl(0,0%,80%);
  border-radius: 4px;
  border-style: solid;
  border-width: 1px;
`;

export const FormRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  @media(max-width: 768px){
    flex-direction: column;
  }
`;

export const FormItem = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: 48%;
  margin-bottom: 2em;
  > span {
    font-weight: bold;
  }
  @media(max-width: 768px){
    width: 100%;
  }
`;

export const CustomInputFormWrapper = styled.div`
  align-items: center;
  background-color: hsl(0,0%,100%);
  border-color: hsl(0,0%,80%);
  border-radius: 4px;
  border-style: solid;
  border-width: 1px;
  cursor: default;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  min-height: 38px;
  outline: 0 !important;
  position: relative;
  transition: all 100ms;
  box-sizing: border-box;
`;

export const InputFormInner = styled.div`
  align-items: center;
  display: flex;
  flex: 1;
  flex-wrap: wrap;
  padding: 2px 0;
  position: relative;
  overflow: hidden;
  box-sizing: border-box;
`;

export const CustomTextInput = styled.input`
  font-size: 14.4px;
  width: 100%;
  padding: 0 8px;
  margin: 0;
  box-sizing: border-box;
  cursor: auto;
  font-family: Avenir;
  border: none;
  height: 32px;
`;

export const CustomDatePicker = styled(DatePicker)`
  font-size: 14.4px;
  width: 365px;
  border: none;
  padding: 0 8px;
  margin: 0;
  box-sizing: border-box;
  cursor: auto;
  font-family: Avenir;
  border: none;
  height: 32px;
  @media(max-width: 768px) {
    width: 344px;
  }
`;

export const ImageUploadBtn = styled.button`
  text-decoration: none;
  text-transform: capitalize;
  font-weight: 500;
  color: #fff;
  vertical-align: top;
  font-family: Avenir;
  background-color: none;
  border: none;
  padding: 15px;
  font-size: 1em;
  width: 100%;
  cursor: pointer;
  border-radius: 4px;
  margin-top: -360px;
  display: block;
  z-index: 1000;
  min-height: 300px;
  > p {
    line-height: 0;
  }
`;

export const CustomFormItem = styled(FormItem)`
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  width: 92%;

`;

export const TogglePair = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-top: 1em;
`;

export const ExceptionWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  flex: 1;
`;
