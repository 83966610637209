import React from 'react';
import 'react-activity-feed/dist/index.css';
import { NavLink } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

/**
 * Global imports
 */
import { DEFAULT_PROFILE } from 'constants/global-default-data';
import { CALCULATE_AGE } from 'utils/global-helpers';
import {
  LeftSidebar,
  SidebarTitle,
  SidebarSubTitle,
  GreetingsWrapper,
  ProfileImageWrapper,
  ProfileImage,
  Greetings,
  SubTitleMissingData,
  MessageButton,
  MessageText,
} from './styles';

const locationToDisplay = (loggedInUserId, user) => {
  if (loggedInUserId === user.id) {
    if (user.location) {
      return <SidebarSubTitle>{user.location.replace(', USA', '')}</SidebarSubTitle>;
    }
    const locationWarning =
      'Your profile is HIDDEN because your location is missing. Consider updating your location by going to your profile edit section';
    return <SubTitleMissingData>{locationWarning}</SubTitleMissingData>;
  }
  // Not currently logged in user
  if (user.location) {
    return <SidebarSubTitle>{user.location}</SidebarSubTitle>;
  }
  return <SidebarSubTitle>{DEFAULT_PROFILE.location}</SidebarSubTitle>;
};

const DashboardSidebar = ({ user, loggedInUserId, startNewConversation, photos }) => (
  <LeftSidebar>
    <ProfileImageWrapper>
      <ProfileImage
        src={user.profile_pic || photos[0]?.url || DEFAULT_PROFILE.image}
        alt="profile"
      />
    </ProfileImageWrapper>
    <GreetingsWrapper>
      <Greetings addBottomMargin={loggedInUserId === user.id}>
        <SidebarTitle>
          {user.first_name || user.last_name}, {CALCULATE_AGE(user.birthday) || ''}
        </SidebarTitle>
        {locationToDisplay(loggedInUserId, user)}
      </Greetings>
    </GreetingsWrapper>
    {loggedInUserId !== user.id && (
      <MessageButton onClick={() => startNewConversation(user.id)} activeOpacity={0.9}>
        <MessageText>Send Icebreaker</MessageText>
      </MessageButton>
    )}
    {loggedInUserId === user.id && (
      <MessageButton onClick={() => user.id} activeOpacity={0.9} loggedInUser>
        <NavLink to={`/profile/edit/${user.id}`}>
          <MessageText loggedInUser>
            <FontAwesomeIcon
              icon="edit"
              color="#fff"
              size="xl"
              style={{ marginTop: -2, marginRight: 5, fontWeight: 200 }}
            />
            Edit profile
          </MessageText>
        </NavLink>
      </MessageButton>
    )}
  </LeftSidebar>
);

export default DashboardSidebar;
