import React from 'react';
import axios from 'axios';
import { CHECK_IS_NEW_MEMBER } from '../../utils/global-helpers';
import { useParams, useNavigate } from 'react-router-dom';
import {
  FormControl,
  FormLabel,
  FormErrorMessage,
  FormHelperText,
  Input,
  Progress,
  Text,
  Box,
  Button,
} from '@chakra-ui/react';
import { ArrowBackIcon } from '@chakra-ui/icons';

/* Constants */
import { BASE_URL } from 'constants/enums';

import { VerificationScreenWrapper, NotificationBar } from '../shared/global-styles';
import { FormTitle, SmallContainer, HeaderTitle, SubTitle, Disclaimer, Success } from './styles';

const VerifyAccount = () => {
  const navigate = useNavigate();
  const { email } = useParams();

  React.useState(() => {
    setTimeout(() => {
      navigate(-1);
    }, 15000);
  }, []);

  return (
    <VerificationScreenWrapper>
      <>
        <Progress size="xs" isIndeterminate />
        <SmallContainer>
          <Box>
            <FormTitle>Check your inbox!</FormTitle>
            <Text mt={12} align="center">
              A confirmation email was sent to:
            </Text>
            <HeaderTitle>{email}</HeaderTitle>
          </Box>
          <Box display="flex" flexDirection="column" alignItems="center">
            <Text align="center" mt={10} mb={6}>
              Confirmation link expires in <b>5 minutes</b>
            </Text>

            <Button
              leftIcon={<ArrowBackIcon />}
              colorScheme="teal"
              variant="link"
              align="center"
              onClick={() => navigate('/')}
            >
              Enter a different email
            </Button>
          </Box>
        </SmallContainer>
      </>
    </VerificationScreenWrapper>
  );
};

export default VerifyAccount;
