// export const BASE_URL = 'https://sevndates.com/api/v1';
// export const BASE_URL = 'https://sevndates-api.herokuapp.com/api/v1'; // HEROKU Temp server TODO: Remove after move to AWS
// export const BASE_URL = 'https://sevndates-service.herokuapp.com/api/v1'; // HEROKU 2 Temp server TODO: Remove after move to AWS
// export const BASE_URL = 'https://sea-lion-app-6ltrj.ondigitalocean.app/api/v1'; // New DigitalOcean (again, i know lol) server
// export const BASE_URL = 'http://10.0.0.20:9000/api/v1'; // local/home
// export const API_URL = 'http://192.168.0.34:9000/api/v1'; // HENRY DAY RD
// export const BASE_URL = 'http://10.138.52.206:9000/api/v1'; // Jobo Main (not guest)
// export const BASE_URL = 'http://192.168.86.144:9000/api/v1'; // BRIARCREEK
// export const BASE_URL = 'http://192.168.4.75:9000/api/v1'; // HILLTOP VILLA / ANDERSON WAY

/**
 * ASSENTS CDN LINKS
 */
export const LOGO_CDN_LINK =
  'https://res.cloudinary.com/sevndates/image/upload/v1551322329/sevndates-logo-white.png';
export const LOGO_ICON_CDN_LINK =
  'https://res.cloudinary.com/sevndates/image/upload/v1551322493/sevndates-logo-icon-pink.png';

export const BASE_URL =
  process.env.NODE_ENV === 'production'
    ? 'https://sea-lion-app-6ltrj.ondigitalocean.app/api/v1'
    : 'http://192.168.4.75:9000/api/v1';
    