import styled from 'styled-components';

export const ThreadWrapper = styled.div`
  max-width: 96%;
  margin: 10px auto 20px auto;
  display: flex;
  flex-direction: column;
`;

export const InnerDivWrapper = styled.div`
  display: flex;
  flex-direction: column;
  word-wrap: break-word;
  margin-bottom: 12px;
  line-height: 24px;
  &:after {
    content: "";
    display: table;
    clear: both;
  }
  &:last-child {
    position:sticky;
    bottom:0;
  }
`;


export const MeChatBubble = styled.div`
  position: relative;
  padding: 10px 20px;
  color: white; 
  background: #0B93F6;
  border-radius: 3px;
  float: right;
  align-self: flex-end;
  margin-bottom: 20px;

  @media(max-width: 748px){
    width: 86%;
  }
    
  &:before {
    content:"";
    position: absolute;
    z-index:100;
    bottom:-2px;
    right:-7px;
    height:20px;
    border-right:20px solid #0B93F6;
    border-bottom-left-radius: 16px 14px;
    -webkit-transform:translate(0, -2px);
  }

  &:after {
    content:"";
    position:absolute;
    z-index:100;
    bottom:-2px;
    right:-56px;
    width:26px;
    height:20px;
    background:white;
    border-bottom-left-radius: 10px;
    -webkit-transform:translate(-30px, -2px);
  }
`;

export const ThemChatBubble = styled.div`
  position:relative;
  padding:10px 20px;
  background:#E5E5EA;
  border-radius: 3px;
  color: black;
  float: left;
  margin-bottom: 20px;
  align-self: flex-start;

  @media(max-width: 748px){
    width: 86%;
  }
    
  &:before {
    content:"";
    position:absolute;
    z-index:2;
    bottom:-2px;
    left:-7px;
    height:20px;
    border-left:20px solid #E5E5EA;
    border-bottom-right-radius: 16px 14px;
    -webkit-transform:translate(0, -2px);
  }

  &:after {
    content:"";
    position:absolute;
    z-index:3;
    bottom:-2px;
    left:4px;
    width:26px;
    height:20px;
    background:white;
    border-bottom-right-radius: 10px;
    -webkit-transform:translate(-30px, -2px);
  }
`;

export const ChatText = styled.p`
  @media(max-width: 414px) {
    font-size: 0.9em;
    margin: 0;
  }
`;
