import React from 'react';
import { fireTracking } from 'index';
import { useParams, useNavigate } from 'react-router-dom';

/* COMPONENTS */
import HomeNav from 'app/home/nav';
import SignInForm from 'app/home/signInForm';
import SignUpForm from 'app/home/signUpForm';
import ResetPasswordRequestForm from 'app/home/resetPasswordRequestForm';

/**
 * Style imports
 */
import {
  CustomContent,
  Container,
  LeftContainer,
  RightContainer,
  Header,
  SubHeader,
} from './styles';

const Home = () => {
  const navigate = useNavigate();
  fireTracking();
  const [submitting, setSubmitting] = React.useState(false);
  const [showLogin, setShowLogin] = React.useState('login');
  const [isLoggedIn, setIsLoggedIn] = React.useState(false);

  const toggleForm = (val) => {
    setShowLogin(val);
  };

  React.useEffect(() => {
    const previouslyLoggedIn = localStorage.getItem('previouslyLoggedIn');
    setShowLogin(previouslyLoggedIn === 'true' ? 'login' : 'register');
    const token = localStorage.getItem('authToken');
    if (token) {
      if (token) setIsLoggedIn(true);
      navigate('/browse');
    }
  }, []);

  return (
    <CustomContent>
      {!isLoggedIn && <HomeNav />}
      {/* <CustomWrapper> */}
      <Container>
        <SignInForm submitting={submitting} toggleForm={toggleForm} setSubmitting={setSubmitting} />
      </Container>
    </CustomContent>
  );
};

export default Home;
