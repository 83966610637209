import React from 'react';
import axios from 'axios';
import { CHECK_IS_NEW_MEMBER } from '../../utils/global-helpers';
import { useParams, useNavigate } from 'react-router-dom';
import { ArrowBackIcon, TimeIcon, CheckCircleIcon, ArrowForwardIcon } from '@chakra-ui/icons';
import { Spinner, Stack, Skeleton, Heading, Button, Box } from '@chakra-ui/react';

/* Constants */
import { BASE_URL } from 'constants/enums';

import { ConfirmationScreenWrapper, NotificationBar } from '../shared/global-styles';
import { FormTitle, SmallContainer, HeaderTitle, SubTitle, Disclaimer, Success } from './styles';
import { saveCookiesOnSuccessfulLogin, LOGOUT } from '../auth/authentication';

const ConfirmEmailMobile = () => {
  const navigate = useNavigate();
  const { token } = useParams();
  const [isError, setIsError] = React.useState(false);
  const [attemptedToOpen, setAttemptedToOpen] = React.useState(false);

  const appDeepLinkUrl = `io.sevndates.com://authloading/${token}`;
  const webUrl = `https://app.sevndates.com/deeplink/confirm-email-mobile?token=${token}`;

  React.useEffect(() => {
    // verifyMagicEmailToken();
  }, []);

  const verifyMagicEmailToken = async () => {
    try {
      const res = await axios.get(`${BASE_URL}/auth/verifyEmail?token=${token}`);
      if (res.status === 200) {
        const { user } = res.data;
        handleOpenApp();
        // if (user) {
        //   // existing user, set cookies and send to browse
        //   await saveCookiesOnSuccessfulLogin(user);
        //   if (user.basic.verified) {
        //     navigate('/browse');
        //   } else {
        //     setTimeout(() => {
        //       navigate(`/complete-onboarding/${user.id}`);
        //     }, 1000);
        //   }
        // }
      } else if (res.status === 403) {
        // setIsError(true);
      }
    } catch (e) {
      // setIsError(true);
    }
  };

  const handleOpenApp = (e) => {
    console.log('gonna try and open app deeplink');
    e.preventDefault();

    // Attempt to open the app
    window.location = appDeepLinkUrl;
    console.log(window);

    // Mark that an attempt was made to open the app
    setAttemptedToOpen(true);

    // Wait a short time and check if the user is still on the page
    setTimeout(() => {
      // If an attempt was made but the user is still here, redirect to the web URL
      if (attemptedToOpen) {
        window.location = webUrl;
      }
    }, 3000); // Adjust time as necessary
  };

  const handleOpenAppTwo = (e) => {
    console.log('gonna try and open app deeplink');
    e.preventDefault();

    // Attempt to open the app
    window.location = `io.sevndates.com://authloading/${token}`;
    console.log(window);

    // Mark that an attempt was made to open the app
    setAttemptedToOpen(true);

    // Wait a short time and check if the user is still on the page
    setTimeout(() => {
      // If an attempt was made but the user is still here, redirect to the web URL
      if (attemptedToOpen) {
        window.location = webUrl;
      }
    }, 3000); // Adjust time as necessary
  };

  return (
    <ConfirmationScreenWrapper>
      <SmallContainer>
        {!isError ? (
          <Box display="flex" flexDirection="column" alignItems="center" pb={10}>
            <CheckCircleIcon color="green.500" mt={6} mb={10} w={20} h={20} />
            <HeaderTitle mb={60}>Almost there!</HeaderTitle>
            <Button
              rightIcon={<ArrowForwardIcon />}
              colorScheme="teal"
              variant="link"
              align="center"
              onClick={() => handleOpenApp()}
            >
              <a href={appDeepLinkUrl} onClick={handleOpenApp}>
                Open app
              </a>
            </Button>
          </Box>
        ) : (
          <>
            <FormTitle>Confirmation Successful!</FormTitle>
            <Spinner color="green.500" mt={10} mb={5} />
            <Disclaimer>Loading account...</Disclaimer>
          </>
        )}
      </SmallContainer>
    </ConfirmationScreenWrapper>
  );
};

export default ConfirmEmailMobile;
