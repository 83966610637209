import React, { Component } from 'react';
import {
  StreamApp,
  StatusUpdateForm,
  NotificationFeed,
  Notification,
  NotificationDropdown,
  FlatFeed,
  Activity,
  LikeButton,
  CommentField,
  CommentList,
  FollowButton,
} from 'react-activity-feed';
import 'react-activity-feed/dist/index.css';

class Timeline extends Component {
  state = {
    user: {},
  };

  componentDidMount() {
    this.getLoggedInUse();
  }

  getLoggedInUse = () => {
    const userMeta = localStorage.getItem('userMeta');
    const user = JSON.parse(userMeta);
    this.setState(() => ({ user }));
  };

  navigateToUserProfile = (id) => {
    const url = `/profile/${id}`;
    window.location.assign(url);
  };

  render() {
    const { user } = this.state;
    if (!user) return 'Loading...';
    return (
      <div
        style={{
          width: 600,
          marginTop: 120,
          marginRight: 'auto',
          marginBottom: 0,
          marginLeft: 'auto',
        }}
      >
        <StreamApp
          apiKey="sxhbxkmw552k"
          appId="29404"
          token="eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VyX2lkIjoiMzVlNTBhNjctN2QxYy00YTgyLWFlZmMtZjRiMjRmMjI2OWM5In0.ksOPsh1MlwVY4FtMuzSyU3AivNA0q1j_grsV0Dd4_PM"
          // token="eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VyX2lkIjoiMzVlNTBhNjctN2QxYy00YTgyLWFlZmMtZjRiMjRmMjI2MTAwIn0.GSZUozD3Fr0o-SFl6bNNvAOfj4RkIDTV1EppYlDB35I"
          defaultUserData={{ name: 'Sevn Dates Member' }}
        >
          {/* <NotificationDropdown
            feedGroup="notification"
            userId={user.id}
            notify
          />
          <NotificationFeed
            Group={props => (
              <Notification
                {...props}
                onClickUser={stuff => console.log(stuff)}
                onClickNotification={notification => console.log(notification)}
              />
            )}
          /> */}
          <StatusUpdateForm
            feedGroup="timeline"
            userId={user.id}
            // userId="35e50a67-7d1c-4a82-aefc-f4b24f226100"
          />
          <FlatFeed
            options={{ reactions: { recent: true } }}
            notify
            activities
            Activity={(props) => (
              <Activity
                {...props}
                onClickUser={() => this.navigateToUserProfile(user.id)}
                onClickMention={() => this.navigateToUserProfile(user.id)}
                follow
                Footer={() => (
                  <div style={{ padding: '8px 16px' }}>
                    <LikeButton {...props} />
                    <CommentField activity={props.activity} onAddReaction={props.onAddReaction} />
                    <CommentList activityId={props.activity.id} />
                  </div>
                )}
              />
            )}
          />
        </StreamApp>
      </div>
    );
  }
}

export default Timeline;
