import styled from 'styled-components';

export const BrowseList = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  align-content: flex-start;
  margin: 60px 0 4% 0!important;
    @media (min-width: 1280px) {
      margin: 0 auto;
    }
`;

// export const Container = styled.div`
//   display: flex;
//   flex-direction: column;
//   background-color: #fff;
//   max-width: 1280px;
//   margin: 0 auto;
// `;

// export const Wrapper = styled.div`
//   display: flex;
//   flex-direction: row;
//   justify-content: flex-start;
//   background-color: #fff;
//   flex-wrap: wrap;
//   padding: 40px;
// `; 

// export const ProfileCard = styled.div`
//     background-color: #eee;
//     overflow: hidden;
//     border-radius: 5px;
//     margin-bottom: 1rem;
//     align-self: flex-start;
//     min-height: 360px;
//     margin-right: 1rem;
//   `;
//   // overflow: hidden - Allows for border radius on image to work

// export const ProfileImage = styled.img`
//   background-color: #fff;
//   resize-mode: cover;
//   border-top-left-radius: 5px;
//   border-top-right-radius: 5px;
//   width: 260px;
//   height: 260px;
// `;

export const LoadMoreButton = styled.button`
  background: #1dd1a1;
  margin: 20px auto 120px auto;
  box-shadow: -1px 3px 8px rgba(0,0,0,0.2);
  position: relative;
  min-width: 100%;
  cursor: pointer;
`;

export const ButtonText = styled.p`
  font-family: Avenir;
  font-size: 16px;
  opacity: 1;
  color: #fff;
  text-align: center;
`;
