export const PROFILE_OPTIONS = [
  { name: 'Bio', icon: 'ios-contact', route: 'Bio' },
  { name: 'Location', icon: 'md-pin', route: 'Location' },
  { name: 'Marital Status', icon: 'ios-heart', route: 'MaritalStatus' },
  { name: 'Sex', icon: 'md-people', route: 'Sex' },
  { name: 'Church Attendance', icon: 'md-calendar', route: 'ChurchAttendance' },
  { name: 'Body Build', icon: 'md-body', route: 'BodyBuild' },
  { name: 'Height', icon: 'md-resize', route: 'Height' },
  { name: 'Education', icon: 'md-school', route: 'Education' },
  { name: 'Occupation', icon: 'md-briefcase', route: 'Occupation' },
  { name: 'Smoking Habits', icon: 'logo-no-smoking', route: 'SmokingHabits' },
  { name: 'Drinking Habits', icon: 'md-wine', route: 'DrinkingHabits' },
  { name: 'Ethnicity', icon: 'md-globe', route: 'Ethnicity' },
  { name: 'Looking for', icon: 'md-star', route: 'LookingFor' },
];

export const HEIGHT_SELECT_OPTIONS = [
  {
    us: '4\'0"',
    metric: 121.92,
    value: 121.92,
    label: '4\'0" (121.92cm)',
  },
  {
    us: '4\'1"',
    metric: 124.46,
    value: 124.46,
    label: '4\'1" (124.46cm)',
  },
  {
    us: '4\'2"',
    metric: 127.0,
    value: 127,
    label: '4\'2" (127.00cm)',
  },
  {
    us: '4\'3"',
    metric: 129.54,
    value: 129.54,
    label: '4\'3" (129.54cm)',
  },
  {
    us: '4\'4"',
    metric: 132.08,
    value: 132.08,
    label: '4\'4" (132.08cm)',
  },
  {
    us: '4\'5"',
    metric: 134.62,
    value: 134.62,
    label: '4\'5" (134.62cm)',
  },
  {
    us: '4\'6"',
    metric: 137.16,
    value: 137.16,
    label: '4\'6" (137.16cm)',
  },
  {
    us: '4\'7"',
    metric: 139.7,
    value: 139.7,
    label: '4\'7" (139.70cm)',
  },
  {
    us: '4\'8"',
    metric: 142.24,
    value: 142.24,
    label: '4\'8" (142.24cm)',
  },
  {
    us: '4\'9"',
    metric: 144.78,
    value: 144.78,
    label: '4\'9" (144.78cm)',
  },
  {
    us: '4\'10"',
    metric: 147.32,
    value: 147.32,
    label: '4\'10" (147.32cm)',
  },
  {
    us: '4\'11"',
    metric: 149.86,
    value: 149.86,
    label: '4\'11" (149.86cm)',
  },
  {
    us: '5\'0"',
    metric: 152.4,
    value: 152.4,
    label: '5\'0" (152.40cm)',
  },
  {
    us: '5\'1"',
    metric: 154.94,
    value: 154.94,
    label: '5\'1" (154.94cm)',
  },
  {
    us: '5\'2"',
    metric: 157.48,
    value: 157.48,
    label: '5\'2" (157.48cm)',
  },
  {
    us: '5\'3"',
    metric: 160.02,
    value: 160.02,
    label: '5\'3" (160.02cm)',
  },
  {
    us: '5\'4"',
    metric: 162.56,
    value: 162.56,
    label: '5\'4" (162.56cm)',
  },
  {
    us: '5\'5"',
    metric: 165.1,
    value: 165.1,
    label: '5\'5" (165.10cm)',
  },
  {
    us: '5\'6"',
    metric: 167.64,
    value: 167.64,
    label: '5\'6" (167.64cm)',
  },
  {
    us: '5\'7"',
    metric: 170.18,
    value: 170.18,
    label: '5\'7" (170.18cm)',
  },
  {
    us: '5\'8"',
    metric: 172.72,
    value: 172.72,
    label: '5\'8" (172.72cm)',
  },
  {
    us: '5\'9"',
    metric: 175.26,
    value: 175.26,
    label: '5\'9" (175.26cm)',
  },
  {
    us: '5\'10"',
    metric: 177.8,
    value: 177.8,
    label: '5\'10" (177.80cm)',
  },
  {
    us: '5\'11"',
    metric: 180.34,
    value: 180.34,
    label: '5\'11" (180.34cm)',
  },
  {
    us: '6\'0"',
    metric: 182.88,
    value: 182.88,
    label: '6\'0" (182.88cm)',
  },
  {
    us: '6\'1"',
    metric: 185.42,
    value: 185.42,
    label: '6\'1" (185.42cm)',
  },
  {
    us: '6\'2"',
    metric: 187.96,
    value: 187.96,
    label: '6\'2" (187.96cm)',
  },
  {
    us: '6\'3"',
    metric: 190.5,
    value: 190.5,
    label: '6\'3" (190.50cm)',
  },
  {
    us: '6\'4"',
    metric: 193.04,
    value: 193.04,
    label: '6\'4" (193.04cm)',
  },
  {
    us: '6\'5"',
    metric: 195.58,
    value: 195.58,
    label: '6\'5" (195.58cm)',
  },
  {
    us: '6\'6"',
    metric: 198.12,
    value: 198.12,
    label: '6\'6" (198.12cm)',
  },
  {
    us: '6\'7"',
    metric: 200.66,
    value: 200.66,
    label: '6\'7" (200.66cm)',
  },
  {
    us: '6\'8"',
    metric: 203.2,
    value: 203.2,
    label: '6\'8" (203.20cm)',
  },
];

export const BODY_BUILD_SELECT_OPTIONS = [
  { value: 1, label: 'Slim' },
  { value: 2, label: 'Average' },
  { value: 3, label: 'Atheletic' },
  { value: 4, label: 'A few extra pounds' },
];

export const CHURCH_ATTENDANCE_SELECT_OPTIONS = [
  { value: 1, label: 'Weekly' },
  { value: 2, label: 'Twice a Month' },
  { value: 3, label: 'Every Month' },
  { value: 4, label: 'Whenever I can' },
];

export const DRINKING_HABITS_SELECT_OPTIONS = [
  { value: 1, label: 'Never Drinks' },
  { value: 2, label: 'Drinks Occassionally' },
  { value: 3, label: 'Drinks Socially' },
  { value: 4, label: 'Drinks Everyday' },
];

export const SMOKING_HABITS_SELECT_OPTIONS = [
  { value: 1, label: 'Never Smokes' },
  { value: 2, label: 'Smokes Occassionally' },
  { value: 3, label: 'Smokes Socially' },
  { value: 4, label: 'Smokes Daily' },
];

export const ETHNICITY_SELECT_OPTIONS = [
  { value: 'ASIAN', label: 'Asian' },
  { value: 'BLACK', label: 'Black' },
  { value: 'HISPANIC', label: 'Hispanic' },
  { value: 'WHITE', label: 'White' },
  { value: 'MIXED', label: 'Mixed' },
];

export const EDUCATION_SELECT_OPTIONS = [
  { value: 1, label: 'High School' },
  { value: 2, label: 'Some College' },
  { value: 3, label: 'University' },
  { value: 4, label: 'Grad School' },
];

export const MARITAL_STATUS_SELECT_OPTIONS = [
  { value: 1, label: 'Single' },
  { value: 2, label: 'Divorced' },
  { value: 3, label: 'Widowed' },
];

export const SEX_SELECT_OPTIONS = [
  { value: 'MALE', label: 'Male' },
  { value: 'FEMALE', label: 'Female' },
];

export const BIRTH_MONTH_SELECT_OPTIONS = [
  { value: '1', label: 'January' },
  { value: '2', label: 'February' },
  { value: '3', label: 'March' },
  { value: '4', label: 'April' },
  { value: '5', label: 'May' },
  { value: '6', label: 'June' },
  { value: '7', label: 'July' },
  { value: '8', label: 'August' },
  { value: '9', label: 'September' },
  { value: '10', label: 'October' },
  { value: '11', label: 'November' },
  { value: '12', label: 'December' },
];

export const BIRTH_DATE_SELECT_OPTIONS = [
  { value: '1', label: '1' },
  { value: '2', label: '2' },
  { value: '3', label: '3' },
  { value: '4', label: '4' },
  { value: '5', label: '5' },
  { value: '6', label: '6' },
  { value: '7', label: '7' },
  { value: '8', label: '8' },
  { value: '9', label: '9' },
  { value: '10', label: '10' },
  { value: '11', label: '11' },
  { value: '12', label: '12' },
  { value: '13', label: '13' },
  { value: '14', label: '14' },
  { value: '15', label: '15' },
  { value: '16', label: '16' },
  { value: '17', label: '17' },
  { value: '18', label: '18' },
  { value: '19', label: '19' },
  { value: '20', label: '20' },
  { value: '21', label: '21' },
  { value: '22', label: '22' },
  { value: '23', label: '23' },
  { value: '24', label: '24' },
  { value: '25', label: '25' },
  { value: '26', label: '26' },
  { value: '27', label: '27' },
  { value: '28', label: '28' },
  { value: '29', label: '29' },
  { value: '30', label: '30' },
  { value: '31', label: '31' },
];

export const BIRTH_YEAR_SELECT_OPTIONS = () => {
  let CURRENT_YEAR = new Date().getFullYear();
  const EARLIEST_YEAR = 1920;
  let DROPDOWN_YEAR_OPTIONS = [];
  while (CURRENT_YEAR - 17 >= EARLIEST_YEAR) {
    DROPDOWN_YEAR_OPTIONS.push({ value: String(CURRENT_YEAR - 17), label: String(CURRENT_YEAR - 17) });
    CURRENT_YEAR -= 1;
  }
  return DROPDOWN_YEAR_OPTIONS;
};

export const INPUT_SPACING = 10;
