import React from 'react';
import { Link } from 'react-router-dom';
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Badge } from '@chakra-ui/react';

/**
 * Global imports
 */
import { DEFAULT_PROFILE } from 'constants/global-default-data';
import { CALCULATE_AGE, CHECK_MEMBERSHIP_LEVEL } from 'utils/global-helpers';
/**
 * Utilities
 */
import {
  HEIGHT_SELECT_OPTIONS,
  // BODY_BUILD_SELECT_OPTIONS,
  // CHURCH_ATTENDANCE_SELECT_OPTIONS,
  // DRINKING_HABITS_SELECT_OPTIONS,
  // SMOKING_HABITS_SELECT_OPTIONS,
  // ETHNICITY_SELECT_OPTIONS,
  // EDUCATION_SELECT_OPTIONS,
  MARITAL_STATUS_SELECT_OPTIONS,
  // SEX_SELECT_OPTIONS,
} from 'constants/profile-constants';

/**
 *
 * @param Styled imports
 */
import {
  CardWrapper,
  // CardHeader,
  // BuyButtonContainer,
  // CardBuyButtonLink,
  // CardFooter,
  // CardFooterLeft,
  // CardFooterRight,
  CardBody,
  CardImageWrapper,
  CardImage,
  CardDetails,
  TitleContainer,
  NewMemberBadge,
  GoldMemberBadge,
  Title,
  SubTitle,
} from './styles';

/**
 * DEFAULT IMAGES
 */
import MALE_AVATAR from '../../../img/male-avatar-sevndates.png';
import FEMALE_AVATAR from '../../../img/female-avatar-sevndates.png';

const getHeight = (height) => {
  const convertedHeight = HEIGHT_SELECT_OPTIONS.filter((obj) => obj.value === height);
  return convertedHeight.length > 0 ? convertedHeight[0].label : '';
};

const getMaritalStatus = (status) => {
  const maritalStatus = MARITAL_STATUS_SELECT_OPTIONS.filter((obj) => obj.value === status);
  return maritalStatus.length > 0 ? maritalStatus[0].label : '';
};

const ProfileCard = ({ user, newMemberBadge }) => {
  const transformImage = (imagePath) =>
    imagePath && imagePath.includes('facebook')
      ? imagePath.replace('facebook', 'facebook/w_600/q_auto')
      : imagePath;

  const getTempAvatar = (gender) => {
    let tempAvatarSrc = '';
    if (gender === 'MALE') {
      tempAvatarSrc = MALE_AVATAR;
    } else {
      tempAvatarSrc = FEMALE_AVATAR;
    }
    return tempAvatarSrc;
  };

  return (
    <CardWrapper>
      {/* {CHECK_MEMBERSHIP_LEVEL(user.id) === 'GOLD MEMBER' && (
      <CardHeader>
        <BuyButtonContainer>
          <CardBuyButtonLink href="#" target="_self" rel="nofollow">
            <span>Gold Member</span>
            <FontAwesomeIcon icon={['fas', 'arrow-right']} />
          </CardBuyButtonLink>
        </BuyButtonContainer>
      </CardHeader>
      )} */}
      <CardBody>
        <Link to={`/profile/${user.id}`}>
          <CardImageWrapper>
            <CardImage
              src={user.profile_pic ? transformImage(user.profile_pic) : getTempAvatar(user.sex)}
            />
          </CardImageWrapper>
          <CardDetails>
            <TitleContainer>
              <Title>
                {user.first_name || user.last_name}
                {user.birthday ? ', ' : ''}
                {CALCULATE_AGE(user.birthday) || ''}
                {user.marital_status ? ' • ' : ''}
                {getMaritalStatus(user.marital_status)}
              </Title>
              {newMemberBadge === 'New Member' && (
                <Badge colorScheme="green" mt={4} mr={0}>
                  New Member
                </Badge>
              )}

              {CHECK_MEMBERSHIP_LEVEL(user.id) === 'GOLD MEMBER' && (
                <Badge colorScheme="green">Gold Member</Badge>
              )}
            </TitleContainer>
            <SubTitle>{user.location ? user.location : DEFAULT_PROFILE.location}</SubTitle>
            <span>
              {getHeight(Number(user.height))}
              {user.height && user.occupation ? ' • ' : ''}
              {user.occupation}
            </span>
          </CardDetails>
        </Link>
      </CardBody>
      {/* <CardFooter>
        <CardFooterLeft>
          <FontAwesomeIcon icon={['fas', 'bolt']} />
            <span>Status</span>
        </CardFooterLeft>
        <CardFooterRight>
          <FontAwesomeIcon icon={['fas', 'heart']} />
          <span>Likes</span>
        </CardFooterRight>
      </CardFooter> */}
    </CardWrapper>
  );
};

export default ProfileCard;
