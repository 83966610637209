import React from 'react';
import axios from 'axios';
import _ from 'lodash';
import { Link } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import { fireTracking } from '../../index';
import { BASE_URL } from '../../constants/enums';
// import io from 'socket.io-client';
import { CHECK_LOGGED_IN_STATUS } from '../auth/authentication';

const OFFSET_COUNT = 32;
const RANDOM = Math.round(Math.random());
// const socket = io(BASE_URL);

import { CHECK_IS_NEW_MEMBER } from '../../utils/global-helpers';

/* Utils */
import { UPDATE_IS_VERIFIED } from 'app/auth/authentication';

/* Components */
import ProfileCard from '../shared/profile-card';

/**
 * Styles imports
 */
import { Wrapper, NotificationBar } from '../shared/global-styles';
import { BrowseList, LoadMoreButton, ButtonText } from './styles';

function BrowseContainer() {
  const { search } = useLocation();

  const [members, setMembers] = React.useState([]);
  const [offset, setOffset] = React.useState(0);
  const [isFetchingMembers, setIsFetchingMembers] = React.useState(false);
  const [agePreference, setAgePreference] = React.useState([18, 100]);
  const [loggedInUser, setLoggedInUser] = React.useState({});
  const [token, setToken] = React.useState('');
  const [orderBy, setOrderBy] = React.useState(['ASC', 'DESC']);
  const [announcement, setAnnouncement] = React.useState('⚠️ Seeing members of the wrong gender?');

  React.useEffect(() => {
    const userMeta = localStorage.getItem('userMeta');
    let user = JSON.parse(userMeta);
    if (search === '?verified=true' && !user.verified) {
      UPDATE_IS_VERIFIED(user);
    }
    CHECK_LOGGED_IN_STATUS();
    fireTracking();
  }, []);

  React.useEffect(() => {
    loadMembers();
    // checkAnnouncement();
  }, []);

  // const checkAnnouncement = () => {
  //   // TIP: you can avoid listening on `connect` and listen on events directly too!
  //   socket.on('connect', () => {
  //     // args are sent in order to acknowledgement function
  //     socket.emit('announcement', 'Tim', 'legend', (res) => {
  //       updateAnnouncement(res);
  //     });
  //   });
  // };

  // data will be 'tobi says woot'
  // const updateAnnouncement = (announcement) => {
  //   setAnnouncement(announcement);
  // };

  const setRandomOffsetCount = async () => {
    const idx = Math.floor(Math.random() * 11);
    const offsetArr = [0, 32, 64, 96, 128, 160, 192, 224, 256, 288, 320];
    const offset = offsetArr[idx];
    const offsetCount = sessionStorage.getItem('offsetCount');
    if (!offsetCount) {
      setOffset(offset);
    }
  };

  const loadMembers = async () => {
    await setRandomOffsetCount(); // Set random value for offset shows diff users every login
    const userMeta = localStorage.getItem('userMeta');
    const tokenLocalStorage = localStorage.getItem('authToken');
    const loggedInUserLocalStorage = JSON.parse(userMeta);
    if (loggedInUserLocalStorage && tokenLocalStorage) {
      setLoggedInUser(loggedInUserLocalStorage);
      setToken(tokenLocalStorage);
      const data = {
        user: {
          id: loggedInUserLocalStorage.id,
          sex: loggedInUserLocalStorage.sex,
        },
        offset,
        agePreference: JSON.stringify(agePreference),
        orderBy: orderBy[RANDOM],
      };
      const options = {
        withCredentials: false,
        headers: { 'Access-Token': tokenLocalStorage },
      };

      const loadedMatches = sessionStorage.getItem('loadedMatches');
      const offsetCount = sessionStorage.getItem('offsetCount');
      const parseSavedMatches = await JSON.parse(loadedMatches);
      const parsedOffsetCount = await JSON.parse(offsetCount);

      // GENDER VERIFICATION
      incompleteProfileVerification(loggedInUser);

      if (!parseSavedMatches) {
        try {
          const res = await axios.post(`${BASE_URL}/discover/potential/browse`, data, options);
          let donCheadle = _.shuffle(res.data.data);
          // TODO: Temporary Gold Member status;
          if (loggedInUser.sex === 'FEMALE') {
            res.data.data.shift();
            // donCheadle = [f.profileFixtures[0], ...res.data.data];
            donCheadle = [...res.data.data];
          }
          if (loggedInUser.sex === 'MALE') {
            res.data.data.shift();
            // donCheadle = [f.profileFixtures[1], ...res.data.data];
            donCheadle = [...res.data.data];
          }

          sessionStorage.setItem('loadedMatches', JSON.stringify(donCheadle));
          sessionStorage.setItem('offsetCount', JSON.stringify(offset + OFFSET_COUNT));
          setMembers(donCheadle);
          setIsFetchingMembers(false);
          setOffset(offset + OFFSET_COUNT);
        } catch (err) {
          // eslint-disable-next-line no-console
          console.log(err, 'error');
        }
      } else {
        setMembers(parseSavedMatches);
        setIsFetchingMembers(false);
        setOffset(offset + parsedOffsetCount);
      }
    }
    return null;
  };

  const onFetchMoreMembers = async () => {
    setIsFetchingMembers(true);
    const data = {
      user: {
        id: loggedInUser.id,
        sex: loggedInUser.sex,
      },
      offset,
      agePreference: JSON.stringify(agePreference),
      orderBy: orderBy[RANDOM],
    };
    const options = {
      withCredentials: false,
      headers: { 'Access-Token': token },
    };
    try {
      const res = await axios.post(`${BASE_URL}/discover/potential/browse`, data, options);
      const shuffledMembers = _.shuffle(res.data.data);
      const latestMembers = [...members, ...shuffledMembers]; // Update new members
      sessionStorage.setItem('loadedMatches', JSON.stringify(latestMembers));
      sessionStorage.setItem('offsetCount', JSON.stringify(offset + OFFSET_COUNT));
      setMembers(latestMembers);
      setIsFetchingMembers(false);
      setOffset(offset + OFFSET_COUNT);
    } catch (err) {
      // eslint-disable-next-line no-console
      console.log(err);
    }
  };

  const incompleteProfileVerification = (user) => {
    const isGenderVerified = localStorage.getItem('isGenderVerified');
    if (user.sex === 'FEMALE' && !JSON.parse(isGenderVerified)) {
      localStorage.setItem('isGenderVerified', false);
      return window.location.assign(`/verify-gender/${user.id}`);
    }
    return null;
  };

  if (!members) return 'Loading...';
  return (
    <Wrapper>
      {(loggedInUser.sex === 'FEMALE' || loggedInUser.sex === null) && (
        <NotificationBar>
          {loggedInUser.sex === 'FEMALE' && announcement}{' '}
          <Link to={`/profile/edit/${loggedInUser.id}?referral=UPDATE_GENDER_NOTICE_HOMEPAGE`}>
            Update your gender information to see matches.
          </Link>
        </NotificationBar>
      )}
      {loggedInUser.sex !== null && (
        <>
          <BrowseList>
            {members.map((member) => {
              const newMemberBadge = CHECK_IS_NEW_MEMBER(member.created_at);
              return <ProfileCard key={member.id} user={member} newMemberBadge={newMemberBadge} />;
            })}
          </BrowseList>
          <LoadMoreButton onClick={() => onFetchMoreMembers()} disabled={isFetchingMembers}>
            <ButtonText>
              {isFetchingMembers ? 'Fetching, please wait...' : 'Show More Members'}
            </ButtonText>
          </LoadMoreButton>
        </>
      )}
    </Wrapper>
  );
}

export default BrowseContainer;
