import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { LOGO_CDN_LINK, LOGO_ICON_CDN_LINK } from 'constants/enums';

/**
 * Stlye imports
 */

import {
  Wrapper,
  GlobalNavLayout, 
  GlobalNavTabs,
  LogoContainer,
  Logo,
  LogoIcon,
  GlobalNavUserSection,
  GlobalNavUserSectionInner,
} from 'app/shared/global-styles';

import { CustomGlobalNav, SocialMediaLink } from './styles';

const HomeNav = () => (
  <CustomGlobalNav>
      <Wrapper>
    <GlobalNavLayout>
        <GlobalNavTabs>
      <LogoContainer to="/">
            <Logo src={LOGO_CDN_LINK} isLoggedOut />
            <LogoIcon src={LOGO_ICON_CDN_LINK} isLoggedOut />
      </LogoContainer>
        </GlobalNavTabs>
        <GlobalNavUserSection>
          <GlobalNavUserSectionInner isLoggedOut>
            <SocialMediaLink href="https://facebook.com/sevndates" target="_blank">
              <FontAwesomeIcon icon={['fab', 'facebook']} />
            </SocialMediaLink>
            <SocialMediaLink href="https://twitter.com/sevndates" target="_blank">
              <FontAwesomeIcon icon={['fab', 'twitter']} />
            </SocialMediaLink>
            <SocialMediaLink href="https://instagram.com/sevndates" target="_blank">
              <FontAwesomeIcon icon={['fab', 'instagram']} />
            </SocialMediaLink>
            <SocialMediaLink href="https://sevndates.com/support">
              <FontAwesomeIcon icon="question-circle" />
            </SocialMediaLink>
          </GlobalNavUserSectionInner>
        </GlobalNavUserSection>
    </GlobalNavLayout>
      </Wrapper>
  </CustomGlobalNav>
);

export default HomeNav;
