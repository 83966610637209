import React from 'react';
import { createRoot } from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import ReactGA from 'react-ga';
import './index.css';
import { library } from '@fortawesome/fontawesome-svg-core';
import { fab, faFacebook, faTwitter, faInstagram } from '@fortawesome/free-brands-svg-icons';
import {
  faCheckSquare,
  faCoffee,
  faEnvelope,
  faBolt,
  faHeart,
  faPlusCircle,
  faCommentAlt,
  faArrowRight,
  faUserShield,
  faIgloo,
  faHeadset,
  faQuestionCircle,
  faClock,
  faCamera,
  faEdit,
} from '@fortawesome/free-solid-svg-icons';
import App from './App';
import * as serviceWorker from './serviceWorker';
import { ChakraBaseProvider, extendBaseTheme, theme as chakraTheme } from '@chakra-ui/react';

library.add(
  fab,
  faFacebook,
  faTwitter,
  faInstagram,
  faCheckSquare,
  faCoffee,
  faEnvelope,
  faBolt,
  faHeart,
  faPlusCircle,
  faCommentAlt,
  faArrowRight,
  faUserShield,
  faIgloo,
  faHeadset,
  faQuestionCircle,
  faClock,
  faCamera,
  faEdit,
);

// Initialize Google Analytics
ReactGA.initialize('UA-119112566-1', {
  debug: false,
  titleCase: true,
});

export const fireTracking = () =>
  ReactGA.pageview(window.location.pathname + window.location.search);

const container = document.getElementById('root');

const { Button } = chakraTheme.components;

const theme = extendBaseTheme({
  components: {
    Button,
  },
  fonts: {
    heading: `mrs-eaves, sans-serif`,
    body: `'Raleway', sans-serif`,
  },
});

const root = createRoot(container); // createRoot(container!) if you use TypeScript
root.render(
  <BrowserRouter>
    <ChakraBaseProvider theme={theme}>
      <App />
    </ChakraBaseProvider>
  </BrowserRouter>,
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
