import React from 'react';
import axios from 'axios';
import { CHECK_IS_NEW_MEMBER } from '../../utils/global-helpers';
import { useParams, useNavigate } from 'react-router-dom';
import { ArrowBackIcon, TimeIcon } from '@chakra-ui/icons';
import { Spinner, Stack, Skeleton, Heading, Button, Box } from '@chakra-ui/react';

/* Constants */
import { BASE_URL } from 'constants/enums';

import { ConfirmationScreenWrapper, NotificationBar } from '../shared/global-styles';
import { FormTitle, SmallContainer, HeaderTitle, SubTitle, Disclaimer, Success } from './styles';
import { saveCookiesOnSuccessfulLogin, LOGOUT } from '../auth/authentication';

const ConfirmEmail = () => {
  const navigate = useNavigate();
  const { token } = useParams();
  const [isError, setIsError] = React.useState(false);

  React.useEffect(() => {
    verifyMagicEmailToken();
  }, []);

  const verifyMagicEmailToken = async () => {
    try {
      const res = await axios.get(`${BASE_URL}/auth/verifyEmail?token=${token}`);
      if (res.status === 200) {
        const { user } = res.data;
        if (user) {
          // existing user, set cookies and send to browse
          await saveCookiesOnSuccessfulLogin(user);
          if (user.basic.verified) {
            navigate('/browse');
          } else {
            setTimeout(() => {
              navigate(`/complete-onboarding/${user.id}`);
            }, 1000);
          }
        }
      } else if (res.status === 403) {
        setIsError(true);
      }
    } catch (e) {
      setIsError(true);
    }
  };

  return (
    <ConfirmationScreenWrapper>
      <SmallContainer>
        {isError ? (
          <Box display="flex" flexDirection="column" alignItems="center" pb={10}>
            <TimeIcon color="gray.500" mt={6} mb={5} w={20} h={20} />
            <FormTitle mb={60}>Confirmation Expired!</FormTitle>
            <Button
              leftIcon={<ArrowBackIcon />}
              colorScheme="teal"
              variant="link"
              align="center"
              onClick={() => LOGOUT()}
            >
              Request a new confirmation link
            </Button>
          </Box>
        ) : (
          <>
            <FormTitle>Confirmation Successful!</FormTitle>
            <Spinner color="green.500" mt={10} mb={5} />
            <Disclaimer>Loading account...</Disclaimer>
          </>
        )}
      </SmallContainer>
    </ConfirmationScreenWrapper>
  );
};

export default ConfirmEmail;
