import React from 'react';
// import MiniCard from '../shared/mini-card';

/**
 * Utility imports
 */
import { getSelectOption } from './profile-utilities';
import { DEFAULT_PROFILE } from 'constants/global-default-data';
import { CHECK_MEMBERSHIP_LEVEL } from 'utils/global-helpers';


import {
  MainContent,
  GraphContainer,
  ScoreContentLeft,
  ScoreContentRight,
  BioWrapper,
  Title,
  BadgeWrapper,
  SubTitle,
  // Score,
  // ScoreUpdate,
  // UpdateNotice,
  // MiniCardsWrapper,
  DetailHeader,
  SpecificsSubTitle,
  CustomGoldMemberBadge,
} from './styles';

// const MiniCardData = [
//   {
//     title: 'Deservd™ Score 🏆',
//     description: 'Last checked Jan 23rd 2019',
//     image:
//       'https://ck-assets.imgix.net/assets/1.53.11/front-door/icons/credit.svg'
//   },
//   {
//     title: 'Unclaimed Deals 🦄',
//     description: 'You have 14 well-deserved unclaimed deals',
//     image:
//       'https://ck-assets.imgix.net/assets/1.53.11/front-door/icons/accounts.svg'
//   },
//   {
//     title: 'Helpful Reviews ⭐',
//     description: 'Recently, you left a helpful review.',
//     image: 'https://ck-assets.imgix.net/assets/1.53.11/front-door/icons/tax.svg'
//   },
//   {
//     title: 'Series Streak 🎉',
//     description: 'Yay you logged in 21 days in a row',
//     image:
//       'https://ck-assets.imgix.net/assets/1.53.11/front-door/icons/auto.svg'
//   }
// ];


const MainProfileContent = ({ user }) => {
  // TODO: Refactor this because it's also being used by seeking component
  const penPal = user.seeking_penpal ? 'Pen Pal, ' : '';
  const friends = user.seeking_friends ? 'Friends, ' : '';
  const longTerm = user.seeking_long_term ? 'Long-Term, ' : '';
  const marriage = user.seeking_marriage ? 'Marriage' : '';
  const seekingArray = [penPal, friends, longTerm, marriage];

  return (
    <MainContent>
      <GraphContainer>
        <BioWrapper>
          <BadgeWrapper>
            {user.bio && (
              <Title>
                {user.first_name}
              's Bio
              </Title>
            )}
            {CHECK_MEMBERSHIP_LEVEL(user.id) === 'GOLD MEMBER' && <CustomGoldMemberBadge>Gold Member</CustomGoldMemberBadge>}
          </BadgeWrapper>
          <SubTitle>
            {user.bio || DEFAULT_PROFILE.description}
          </SubTitle>
        </BioWrapper>
        <>
          <ScoreContentLeft>
            <DetailHeader>Marital Status</DetailHeader>
            {getSelectOption('MARITAL_STATUS_SELECT_OPTIONS', user.marital_status)}

            <DetailHeader>Education</DetailHeader>
            {getSelectOption('EDUCATION_SELECT_OPTIONS', user.education)}

            <DetailHeader>Ethnicity</DetailHeader>
            {getSelectOption('ETHNICITY_SELECT_OPTIONS', user.ethnicity)}
            
            <DetailHeader>Height</DetailHeader>
            {getSelectOption('HEIGHT_SELECT_OPTIONS', user.height)}
            
            <DetailHeader>Body Type</DetailHeader>
            {getSelectOption('BODY_BUILD_SELECT_OPTIONS', user.body_build)}
          </ScoreContentLeft>

          <ScoreContentRight>
            <DetailHeader>Church Attendance</DetailHeader>
            {getSelectOption('CHURCH_ATTENDANCE_SELECT_OPTIONS', user.church_attendance_habits)}

            <DetailHeader>Occupation</DetailHeader>
            <SpecificsSubTitle>{user.occupation || 'N/A'}</SpecificsSubTitle>

            <DetailHeader>Smoking Habits</DetailHeader>
            {getSelectOption('SMOKING_HABITS_SELECT_OPTIONS', user.smoking_habits)}

            <DetailHeader>Drinking Habits</DetailHeader>
            {getSelectOption('DRINKING_HABITS_SELECT_OPTIONS', user.drinking_habits)}

            <DetailHeader>Seeking</DetailHeader>
            <SpecificsSubTitle>
              {penPal || friends || longTerm || marriage ? seekingArray.map(val => val) : 'Dating'}
            </SpecificsSubTitle>
          </ScoreContentRight>
          {/* <UpdateNotice>
            Updated Jan 24, 2019 • Calculated using DeservdScore™ 2.0
          </UpdateNotice> */}
        </>
      </GraphContainer>
      {/* <MiniCardsWrapper>
        {MiniCardData.map(x => (
          <MiniCard data={x} key={x.title} />
        ))}
      </MiniCardsWrapper> */}
    </MainContent>
  );
};

export default MainProfileContent;
