import { initializeApp } from 'firebase/app';
import { getFirestore, collection, getDocs } from 'firebase/firestore/lite';

const firebaseConfig = {
  apiKey: 'AIzaSyDUet18ql49PdTMFWpwvx2XYWJE6eG4gbg',
  authDomain: 'sevn-dates.firebaseapp.com',
  databaseURL: 'https://sevn-dates.firebaseio.com',
  projectId: 'sevn-dates',
  storageBucket: 'sevn-dates.appspot.com',
  messagingSenderId: '918764817114',
};

// const firestore = initializeApp(config);
const app = initializeApp(firebaseConfig);
export const db = getFirestore(app);

// export default firestore;
