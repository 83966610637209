import React from 'react';
import axios from 'axios';
// import { getFirestore, collection, getDocs } from 'firebase/firestore/lite';
import { useParams } from 'react-router-dom';
import { db } from 'utils/firestore';
import { useLocation } from 'react-router-dom';

/*
 * Helpers
 */
import { fireTracking } from 'index';
import { CHECK_LOGGED_IN_STATUS } from 'app/auth/authentication';
import { BASE_URL } from 'constants/enums';
import firestore from 'utils/firestore';
import { SNITCH_PROFILE_VISITS } from 'utils/visitor-notification';

/*
 * Components
 */
import Profile from './profile';

function ProfileContainer() {
  const [user, setUser] = React.useState({});
  const [profile, setProfile] = React.useState({});
  const [loggedInUser, setLoggedInUser] = React.useState({});
  const [loggedInUserId, setLoggedInUserId] = React.useState('');
  const [token, setToken] = React.useState('');
  const [uniqueViews, setUniqueViews] = React.useState([]);
  const [profileVisitors, setProfileVisitors] = React.useState([]);
  const [photos, setPhotos] = React.useState([]);

  const { id } = useParams();
  const location = useLocation();

  React.useEffect(() => {
    CHECK_LOGGED_IN_STATUS();
    setUpComponent();
    fireTracking();
  }, []);

  React.useCallback(async () => {
    await fetchProfile();
    if (id !== loggedInUserId) {
      fireTracking();
    }
  }, [location.pathname]);

  const setUpComponent = async () => {
    const localToken = localStorage.getItem('authToken');
    const userMeta = localStorage.getItem('userMeta');
    const parsedUserMeta = JSON.parse(userMeta);
    setLoggedInUserId(parsedUserMeta.id);
    setUser(parsedUserMeta);
    setToken(localToken);
    await fetchProfile();
  };

  const fetchProfile = async () => {
    // setProfile(null);
    // if (id !== loggedInUserId || loggedInUser === null) {
    if (id) {
      try {
        const res = await axios.get(`${BASE_URL}/users/${id}`, {
          withCredentials: false,
          headers: { 'Access-Token': token },
        });
        const profile = res.data.data;
        setProfile(profile);
        setPhotos(res.data.photos);
        if (loggedInUser !== null) setLoggedInUser(profile);
        recordProfileView(profile, user);
        fetchProfileVisitor(user);
        fetchUniqueViews(user);
      } catch (err) {
        throw new Error(err);
      }
    }
    // } else {
    //   setProfile()
    // }
  };

  const startNewConversation = async (userId) => {
    const conversationData = {
      user_id: loggedInUserId,
      recipient_id: userId,
    };

    const options = {
      withCredentials: false,
      headers: { 'Access-Token': token },
    };

    try {
      const res = await axios.post(`${BASE_URL}/conversations`, conversationData, options);
      if (res) {
        window.location.assign('/messages');
      }
    } catch (err) {
      throw new Error(err);
    }
  };

  /**
   * Viewer = Currently logged in user;
   * Viewed = The person viewer above is checking out
   **/
  const recordProfileView = (viewed, viewer) => {
    if (viewed.id !== viewer.id) {
      // Send email notification and add log to Firebase
      SNITCH_PROFILE_VISITS(viewed, viewer);
      // const db = firebase.firestore();

      // const db = getFirestore(firestore);
      db.collection('profile-views').add({
        time_viewed: new Date(),
        viewed_avatar: viewed.profile_pic,
        viewed_first_name: viewed.first_name,
        viewed_last_name: viewed.last_name,
        viewed_id: viewed.id,
        viewer_avatar: viewer.avatar,
        viewer_first_name: viewer.first_name,
        viewer_last_name: viewer.last_name,
        viewer_id: viewer.id,
      });
    }
  };

  const fetchUniqueViews = async (user) => {
    const loggedInUserId = user.id;
    // const db = getFirestore(firestore);
    // const db = firebase.firestore();
    const profileViewsRef = db.collection('profile-views');
    const query = profileViewsRef
      .where('viewer_id', '==', loggedInUserId)
      .orderBy('time_viewed', 'desc')
      .limit(20);
    const viewedProfile = [];
    await query.get().then((snapshot) => {
      snapshot.forEach((doc) => {
        viewedProfile.push(doc.data());
      });
    });
    let uniqueViews = viewedProfile.filter(
      (obj, pos) => viewedProfile.map((mapObj) => mapObj.viewed_id).indexOf(obj.viewed_id) === pos,
    );
    uniqueViews = uniqueViews.slice(0, 10); // Limit only to 10 items;
    setUniqueViews(uniqueViews);
  };

  const fetchProfileVisitor = async (user) => {
    const loggedInUserId = user.id;
    // const db = getFirestore(firestore);
    const profileViewsRef = db.collection('profile-views');
    const query = profileViewsRef
      .where('viewed_id', '==', loggedInUserId)
      .orderBy('time_viewed', 'desc')
      .limit(20);

    const visitors = [];
    await query.get().then((snapshot) => {
      snapshot.forEach((doc) => {
        visitors.push(doc.data());
      });
    });

    let profileVisitorsArr = visitors.filter((obj, pos, viewedProfile) => {
      return viewedProfile.map((mapObj) => mapObj.viewer_id).indexOf(obj.viewer_id) === pos;
    });
    profileVisitorsArr = profileVisitorsArr.slice(0, 10); // Limit only to 10 items;
    setProfileVisitors(profileVisitorsArr);
  };

  if (!profile || !loggedInUserId || !token || !id) return 'Loading...';

  return (
    <Profile
      user={profile}
      paramsId={id}
      loggedInUserId={loggedInUserId}
      startNewConversation={startNewConversation}
      uniqueViews={uniqueViews}
      profileVisitors={profileVisitors}
      photos={photos}
    />
  );
}

export default ProfileContainer;
