// Render Prop
import React from 'react';
import { Formik } from 'formik';
import Select from 'react-select';

import { SEX_SELECT_OPTIONS } from '../../../constants/profile-constants';
import {
  getEditSelectOption,
  mapOptionToValue,
} from '../../profile/profile-utilities';

/**
 * Global imports
 */
import { Wrapper, HeaderTitle } from '../../shared/global-styles';
import { SendButton } from '../../conversations/styles';
import { DashboardWrapper } from '../../profile/styles';
import { FormItem, VerificationContainer } from './styles';

const VerifyGender = ({
  user,
  onGenderUpdate,
}) => {
  let transformedBirthday = new Date(user.birthday);
  transformedBirthday = transformedBirthday.setTime(
    transformedBirthday.getTime() + transformedBirthday.getTimezoneOffset() * 60 * 1000,
  );
  return (
    <Wrapper>
      <Formik
        initialValues={{
          id: user.id,
          facebook_id: user.facebook_id,
          bio: user.bio,
          birthday: transformedBirthday,
          occupation: user.occupation,
          profilePic: user.profile_pic,
          sex: mapOptionToValue('sex', user.sex),
          maritalStatus: mapOptionToValue('maritalStatus', user.marital_status),
          churchAttendance: mapOptionToValue(
            'churchAttendance',
            user.church_attendance_habits,
          ),
          ethnicity: mapOptionToValue('ethnicity', user.ethnicity),
          height: mapOptionToValue('height', user.height),
          bodyBuild: mapOptionToValue('bodyBuild', user.body_build),
          education: mapOptionToValue('education', user.education),
          smokingHabits: mapOptionToValue('smokingHabits', user.smoking_habits),
          drinkingHabits: mapOptionToValue(
            'drinkingHabits',
            user.drinking_habits,
          ),
          seekingFriends: user.seeking_friends,
          seekingLongTerm: user.seeking_long_term,
          seekingMarriage: user.seeking_marriage,
          seekingPenPal: user.seeking_penpal,
          firstName: user.first_name,
          lastName: user.last_name,
          email: user.email,
          religion: user.religion,
          city: user.city,
          location: user.location,
          state: user.state,
          county: user.county,
          country: user.country,
          zipCode: user.zip_code,
          geoLatitude: user.geo_latitude,
          geoLongitude: user.geo_longitude,
          previouslyLoggedIn: true,
        }}
        onSubmit={(values) => {
          onGenderUpdate(values);
        }}
      >
        {({ values, setFieldValue }) => (
          <DashboardWrapper>
            <VerificationContainer>
              <HeaderTitle>What is your gender?</HeaderTitle>
              <FormItem>
                <Select
                  value={values.sex}
                  onChange={option => getEditSelectOption('sex', option, setFieldValue)}
                  options={SEX_SELECT_OPTIONS}
                  name="sex"
                />
              </FormItem>
              <SendButton type="submit" onClick={() => onGenderUpdate(values)}>
                Update
              </SendButton>
            </VerificationContainer>
          </DashboardWrapper>
        )}
      </Formik>
    </Wrapper>
  );
};

export default VerifyGender;
