import React from 'react';

/**
 * Style imports
 */
import {
  SectionTitle,
  ProfileViewsContainer,
  CustomLink,
  VisitorWrapper,
  AvatarContainer,
  VisitorAvatar,
  VisitorName,
} from './styles';

const ProfileViews = ({ profileVisitors, uniqueViews }) => {
  const viewedMeCount = profileVisitors.length;
  let verb = 'People';
  if (viewedMeCount === 1) verb = 'Person';
  return (
    <>
      {/* VISITORS */}
      {profileVisitors.length > 0 && (
        <SectionTitle>
          {viewedMeCount} {verb} viewed you
        </SectionTitle>
      )}
      <ProfileViewsContainer>
        {profileVisitors.map((visitor) => {
          const profilePic = visitor.viewer_avatar && visitor.viewer_avatar.includes('facebook')
            ? visitor.viewer_avatar.replace('facebook', 'facebook/w_300/q_auto')
            : visitor.viewer_avatar;
          return (
            <CustomLink
              to={`/profile/${visitor.viewer_id}`}
              key={visitor.viewer_avatar}
            >
              <VisitorWrapper>
                <AvatarContainer>
                  <VisitorAvatar
                    src={profilePic}
                    alt={visitor.viewer_first_name}
                  />
                </AvatarContainer>
                <VisitorName>
                  {visitor.viewer_first_name
                    ? visitor.viewer_first_name
                    : visitor.viewer_last_name}
                </VisitorName>
              </VisitorWrapper>
            </CustomLink>
          );
        })}
      </ProfileViewsContainer>

      {/* BROWSE HISTORY */}
      {uniqueViews.length > 0 && <SectionTitle>Recently viewed</SectionTitle>}
      <ProfileViewsContainer>
        {uniqueViews.map((visited) => {
          const profilePic = visited.viewed_avatar && visited.viewed_avatar.includes('facebook')
            ? visited.viewed_avatar.replace('facebook', 'facebook/w_300/q_auto')
            : visited.viewed_avatar;
          return (
            <CustomLink
              to={`/profile/${visited.viewed_id}`}
              key={visited.viewed_avatar}
            >
              <VisitorWrapper>
                <AvatarContainer>
                  <VisitorAvatar
                    src={profilePic}
                    alt={visited.viewed_first_name}
                  />
                </AvatarContainer>
                <VisitorName>
                  {visited.viewed_first_name
                    ? visited.viewed_first_name
                    : visited.viewed_last_name}
                </VisitorName>
              </VisitorWrapper>
            </CustomLink>
          );
        })}
      </ProfileViewsContainer>
    </>
  );
};

export default ProfileViews;
