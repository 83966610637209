import styled from 'styled-components';
import { Link } from 'react-router-dom';

export const Content = styled.div`
  min-height: 60vh;
  overflow-x: hidden;
  width: 100%;
`;

export const Wrapper = styled.div`
  box-sizing: border-box;
  margin: 0 auto;
  max-width: 1280px;
  min-width: 310px;
  ${'' /* padding: 0 20px; */}
  position: relative;
  width: 100%;
  @media only screen and (max-width: 985px) {
    padding: 0 20px;
  }
  @media only screen and (max-width: 540px) {
    padding: 0 15px;
  }
`;

export const ConfirmationScreenWrapper = styled(Wrapper)`
  background: linear-gradient(135deg, rgb(255, 51, 101) 0%, rgb(98, 58, 162) 100%)};
  min-width: 100%;
  min-height: 100vh;
  margin: 0 auto;
  padding-top: 50px;
`;

export const VerificationScreenWrapper = styled(ConfirmationScreenWrapper)`
  padding-top: 0px;
`;

export const ContentWrapper = styled.div`
  margin: 90px auto;
  max-width: 1240px;
  padding: 0 20px;
`;

export const GlobalNav = styled.div`
  background: linear-gradient(135deg, rgb(255, 51, 101) 0%, rgb(98, 58, 162) 100%);
  border-bottom: 1px solid rgba(0, 0, 0, 0.07);
  box-shadow: 0px 3px 8px -4px rgba(0, 0, 0, 0.15);
  height: 68px;
  position: fixed;
  top: 0;
  -webkit-transform: translateZ(0);
  transform: translateZ(0);
  -webkit-transition-property: background-color, height;
  transition-property: background-color, height;
  -webkit-transition-duration: 0.2s;
  transition-duration: 0.2s;
  -webkit-transition-timing-function: ease-in-out;
  transition-timing-function: ease-in-out;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  width: 100%;
  z-index: 2000;
  @media only screen and (max-width: 540px) {
    // line-height: 80px;
  }
  @media only screen and (max-width: 400px) {
    // line-height: 64px;
  }
`;

export const GlobalNavLayout = styled.div`
  height: 100%;
  display: -ms-flexbox;
  display: -webkit-box;
  display: flex;
  display: -webkit-flex;
  margin-top: 21px;
  -ms-flex-direction: row;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  flex-direction: row;
  -ms-flex-pack: justify;
  -webkit-box-pack: justify;
  justify-content: space-between;
`;

export const GlobalNavTabs = styled.div`
  height: 100%;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
`;

export const NavLink = styled(Link)`
  margin-right: 20px;
  text-decoration: none;
  color: #fff;
  font-family: Avenir;
  font-weight: 500;
  margin-top: 2px;
  &:hover {
    opacity: 0.6;
  }
  @media (max-width: 387px) {
    margin-right: 10px;
  }
`;

export const LogoContainer = styled(Link)`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  margin-top: -4px;
  margin-right: 60px;
  @media (max-width: 611px) {
    margin-right: 15px;
  }
`;

export const Logo = styled.img`
  width: 200px;
  @media (max-width: 611px) {
    display: ${(props) => (props.isLoggedOut ? 'inline-flex' : 'none')};
  }
  @media (max-width: 444px) {
    display: none;
  }
`;

const getLogoIconResponsiveDisplay = (props) => {
  if (!props.isLoggedOut) {
    return `@media(max-width: 611px) {
          width: 50px;
          display: inline-flex;
          margin-top: -5px;
          filter: brightness(0) invert(1);
        }`;
  }
  return `
    @media(max-width: 444px) {
        width: 50px;
        display: inline-flex;
        margin-top: -10px;
        filter: brightness(0) invert(1);
      }`;
};

export const LogoIcon = styled.img`
  display: none;
  ${(props) => getLogoIconResponsiveDisplay(props)}
`;

export const LogoWordmark = styled(Link)`
  font-weight: 700;
  font-size: 1.2rem;
  color: #fff !important;
  font-family: Avenir;
  opacity: 1;
  &:hover {
    opacity: 0.8;
  }
`;

export const HeaderTitle = styled.h1`
  font-family: Avenir;
  font-size: 2em;
`;

export const HeaderSubTitle = styled.h2`
  font-family: Avenir;
  font-size: 1.4em;
`;

export const GlobalNavUserSection = styled.div`
  // display: -ms-flexbox;
  // display: -webkit-box;
  // display: flex;
  // display: -webkit-flex;
  // -ms-flex-direction: row;
  // -webkit-box-orient: horizontal;
  // -webkit-box-direction: normal;
  // flex-direction: row;
  // -ms-flex-align: center;
  // -webkit-box-align: center;
  // align-items: center;
  position: relative;
`;

export const GlobalNavUserSectionInner = styled.div`
  display: -ms-flexbox;
  display: -webkit-box;
  display: flex;
  display: -webkit-flex;
  -ms-flex-direction: row;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  flex-direction: row;
  -ms-flex-align: center;
  -webkit-box-align: center;
  align-items: center;
  position: relative;
  padding-right: ${(props) => (props.isLoggedOut ? '20px' : 0)};
`;

export const GlobalNavSearchContainer = styled.div`
  position: relative;
  margin: 0 4px;
  outline: none;
  width: 250px;
  width: 100%;
  @media only screen and (max-width: 680px) {
    display: none;
  }
`;

export const LoginButton = styled.a`
  border: none;
  border-radius: 50px;
  box-sizing: border-box;
  cursor: pointer;
  line-height: 30px;
  font-size: 15px;
  height: 30px;
  position: relative;
  padding: 0 20px 0;
  text-decoration: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  margin: 0 2px;
  padding: 0 8px 0;
  color: #a6a6a6;
  font-weight: 500;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  -webkit-transition: color 0.2s ease-in-out;
  transition: color 0.2s ease-in-out;
  @media only screen and (max-width: 400px) {
    font-size: 14px;
    padding: 0 14px 0;
  }
  &focus {
    text-decoration: none;
    outline: none;
  }
  &hover {
    color: #595959;
  }
  @media only screen and (max-width: 540px) {
    padding: 0 6px;
  }
  @media only screen and (max-width: 400px) {
    display: none;
  }
`;

export const SignUpButton = styled(Link)`
  font-family: Avenir;
  font-weight: 500;
  border: none;
  border-radius: 50px;
  box-sizing: border-box;
  cursor: pointer;
  line-height: 30px;
  font-size: 15px;
  height: 30px;
  position: relative;
  padding: 0 40px 0;
  text-decoration: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  margin: 0 2px;
  background: #fd3366;
  color: white;
  -moz-osx-font-smoothing: initial;
  -webkit-font-smoothing: subpixel-antialiased;
  -webkit-transition-property: background-color, font-size, padding;
  transition-property: background-color, font-size, padding;
  -webkit-transition-duration: 0.2s;
  transition-duration: 0.2s;
  -webkit-transition-timing-function: ease-in-out;
  transition-timing-function: ease-in-out;

  @media only screen and (max-width: 400px) {
    font-size: 14px;
    padding: 0 14px 0;
  }

  &focus {
    text-decoration: none;
  }
  &hover {
    text-decoration: none;
    background-color: #4533b6;
  }
`;

export const FacebookLoginButton = styled.button`
  font-family: Avenir;
  font-weight: 500;
  border: none;
  border-radius: 5px;
  box-sizing: border-box;
  cursor: pointer;
  line-height: 30px;
  font-size: 15px;
  height: 30px;
  position: relative;
  padding: 0 20px 5px;
  text-decoration: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  margin: 0 2px;
  background: #4267b2;
  color: white;
  -moz-osx-font-smoothing: initial;
  -webkit-font-smoothing: subpixel-antialiased;
  -webkit-transition-property: background-color, font-size, padding;
  transition-property: background-color, font-size, padding;
  -webkit-transition-duration: 0.2s;
  transition-duration: 0.2s;
  -webkit-transition-timing-function: ease-in-out;
  transition-timing-function: ease-in-out;

  @media only screen and (max-width: 400px) {
    font-size: 14px;
    padding: 0 14px 0;
  }

  &focus {
    text-decoration: none;
  }
  &hover {
    text-decoration: none;
    background-color: #4533b6;
  }
`;

export const FacebookLoginButtonBig = styled(FacebookLoginButton)`
  border-radius: 50px;
  width: 400px;
  background-color: #365899;
  color: #fff;
  margin-bottom: 40px;
  font-family: Avenir;
  font-weight: 100;
  padding: 10px 20px 40px 20px;
  margin-top: 20px;
  border: none;
  &:hover {
    background-color: #1d3c78;
  }
  @media (max-width: 444px) {
    width: 90%;
    padding: 10px 20px 40px 20px;
  }
`;

export const SuperScript = styled.h6`
  font-family: Avenir;
  color: #fff;
  font-weight: 400;
  margin-top: -30px;
  margin-left: 60px;
  font-style: italic;
  opacity: 0.4;
  cursor: pointer;
  &:hover {
    opacity: 1;
  }
  @media (max-width: 444px) {
    margin-left: 10px;
  }
`;

export const GlobalNavSearch = styled.input`
  background-color: rgba(235, 235, 235, 0.6);
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border-radius: 300px;
  outline: none;
  padding: 0 31px;
  border: none;
  height: 30px;
  font-weight: 500;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  color: #212121;
  box-sizing: border-box;
  font-size: 14px;
  width: 100%;
  display: block;
  -webkit-transition: width 0.2s ease-out;
  transition: width 0.2s ease-out;
`;

export const NotificationBar = styled.div`
  background-color: #fbed75;
  margin: 102px auto -40px auto;
  font-size: 1em;
  padding: 2em;
  text-align: center;
  border-radius: 4px;
  max-width: 800px;
  box-shadow: 0px 10px 30px #adabab;
  font-weight: 600;
  color: #3a3a3c;
  a {
    color: rgb(98, 58, 162);
  }
`;
