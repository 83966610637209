import React from 'react';

/**
 * Style imports
 */
import {
  ThreadWrapper,
  InnerDivWrapper,
  MeChatBubble,
  ThemChatBubble,
  ChatText,
} from 'app/conversations/chat/styles';

const Chat = ({ loggedInUserId, messageThread }) => {
  const reversedThread = messageThread.sort((a, b) => {
    return b.id - a.id;
  });


  return(
    <ThreadWrapper mode="bottom">
      <InnerDivWrapper>
        {reversedThread.reverse().map((x) => {
          if (x.sender_id === loggedInUserId) {
            return (
              <MeChatBubble key={x.id}>
                <ChatText>{x.content}</ChatText>
              </MeChatBubble>
            )
          }
          return (
          <ThemChatBubble key={x.id}>
            <ChatText>{x.content}</ChatText>
          </ThemChatBubble>
          )
        })}
      </InnerDivWrapper>
    </ThreadWrapper>
  )
};

export default Chat;
