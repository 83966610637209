export const CALCULATE_AGE = (birthday) => {
  let age = '';
  if (birthday) {
    const today = new Date();
    const birthDate = new Date(birthday);
    age = today.getFullYear() - birthDate.getFullYear();
    const m = today.getMonth() - birthDate.getMonth();
    if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
      age--;
    }
  }
  return age;
};

// Format Time
export const FORMAT_DAYS_AGO = (matchDate) => {
  const oneDay = 24 * 60 * 60 * 1000; // hours*minutes*seconds*milliseconds
  const firstDate = new Date(matchDate);
  const secondDate = new Date();

  let daysAgo = Math.round(Math.abs((firstDate.getTime() - secondDate.getTime()) / oneDay));

  if (daysAgo === 0) {
    daysAgo = 'Today';
    return daysAgo;
  } if (daysAgo === 1) {
    daysAgo = 'Yesterday';
    return daysAgo;
  } if (daysAgo > 7 && daysAgo < 14) {
    daysAgo = 'Last week';
    return daysAgo;
  } if (daysAgo === 14) {
    daysAgo = '2 weeks';
    return daysAgo;
  } if (daysAgo > 29 && daysAgo < 60) {
    daysAgo = '1 month ago';
    return daysAgo;
  } if (daysAgo > 59 && daysAgo < 90) {
    daysAgo = '2 months ago';
    return daysAgo;
  } if (daysAgo > 89 && daysAgo < 120) {
    daysAgo = '3 months ago';
    return daysAgo;
  }
  daysAgo = `${daysAgo} days ago`;
  return daysAgo;
};

export const CONSOLE_LOG_STRING = 'XXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXX';

// Return varius badges, currently only returns New Member
/**
 *
 * @param {String} date;
 */
export const CHECK_IS_NEW_MEMBER = (date) => {
  const today = new Date();
  const dateJoined = new Date(date);
  const daysDifference = Math.floor((today.getTime() - dateJoined.getTime()) / (1000 * 60 * 60 * 24));
  if (daysDifference <= 21) return 'New Member';
  return '';
};

export const CHECK_MEMBERSHIP_LEVEL = (id) => {
  // if (id === '35e50a67-7d1c-4a82-aefc-f4b24f2269c9' || id === '4ea05e0b-7960-4de5-993e-11d553ed8f3b') return 'GOLD MEMBER';
  if (id === 'NOTHING') return 'GOLD MEMBER';
  return '';
};
