import styled from 'styled-components';
import { Wrapper } from 'app/shared/global-styles';

/**
 * Component wide styles
 */
export const Container = styled(Wrapper)`
  margin: 100px auto 0 auto;
`;

export const ExternalFlexWrapper = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
`;

export const Title = styled.p`
  font-size: 18px;
  font-weight: 800;
  opacity: 0.8;
  font-family: Avenir;
  color: #333;
  margin-top: 7px;
  margin-bottom: 0;
  @media (max-width: 748px) {
    display: none;
  }
`;

export const DefaultPageTitle = styled(Title)`
  text-align: center;
  @media (max-width: 748px) {
    display: flex;
  }
`;

/**
 * Left column
 */
export const VerticleFlexWrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: flex-start;
  width: calc(100% / 3.1 * 1);
  @media (max-width: 748px) {
    width: 25%;
  }
`;

export const ScrollableConversationSection = styled.div`
  max-height: 380px;
  overflow-y: scroll;
  @media (max-width: 748px) {
    max-height: 500px;
  }
`;

const adImageUrl =
  'https://www.brandchannel.com/wp-content/uploads/2016/05/old-navy-interracial-ad-600.jpg';
// Dimension should be 336 x 280 (Large Rectangle)
export const AdPlaceholder = styled.div`
  width: 100%;
  height: 300px;
  background: pink;
  margin-top: 20px;
  margin-bottom: 30px;
  background-image: url(${adImageUrl});
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
`;

// CONVO LIST CONTAINER (LEFT)
export const ConvoListWrapper = styled.div`
  display: flex;
  background-color: #fff;
  flex-direction: row;
  flex-wrap: wrap;
  margin-bottom: 5px;
  padding: 10px 10px 20px 20px;
  background-color: ${(props) => (props.currentConvo ? '#f9f9f9' : '#f3f3f3')};
  cursor: pointer;
  &:hover {
    opacity: 0.8;
    background: #f9f9f9;
  }
  @media (max-width: 748px) {
    padding: 10px;
  }
`;

export const ProfileImage = styled.img`
  width: 80px;
  height: 80px;
  margin-right: 20px;
  margin-horizontal: 14px;
  background-color: #fff;
  resize-mode: cover;
  border-radius: 40px;
  @media (max-width: 748px) {
    margin-right: 0;
  }
  @media (max-width: 414px) {
    width: 30px;
    height: 30px;
  }
`;

export const ContentWrapper = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: flex-start;
`;

export const InnerWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: row;
`;

export const SubTitle = styled.p`
  align-items: flex-start;
  font-size: 16px;
  opacity: 0.6;
  line-height: 1.2;
  font-family: Avenir;
  margin: 0;
  @media (max-width: 997px) {
    display: none;
  }
`;

export const TimeAgo = styled.p`
  align-items: flex-start;
  font-size: 15px;
  margin-top: 7px;
  margin-right: 0;
  margin-bottom: 0;
  opacity: 0.3;
  color: #333;
  font-family: Avenir;
  @media (max-width: 997px) {
    display: none;
  }
`;

export const RightChevronContainer = styled.div`
  flex-grow: 0;
  flex-shrink: 1;
  margin-left: 5px;
  padding-top: 9px;
  margin-right: 5px;
  @media (max-width: 997px) {
    display: none;
  }
`;

/**
 *  Right column
 */
// MESSAGE THREAD CONTAINER
export const ConvoDetailsWrapper = styled.div`
  margin-left: 12px;
  width: calc(100% / 3 * 2);
  display: flex;
  flex-direction: column;
  @media (max-width: 1159px) {
    width: calc(100% / 3.1 * 2);
  }
  @media (max-width: 748px) {
    width: 70%;
  }
`;

export const ScrollableMessageThread = styled.div`
  max-height: 460px;
  height: 460px;
  overflow-y: scroll;
  background: #fff;
  padding: 20px;
  color: #333;
  font-family: Avenir;
  border: solid 1px #f3f3f3;
`;

export const CustomTextArea = styled.textarea`
  resize: none;
  border: none;
  display: block;
  width: 100%;
  height: 80px;
  border-radius: 3px;
  padding: 20px;
  font-size: 16px;
  margin-top: 15px;
  margin-bottom: 13px;
  font-family: Avenir;
  background: #f3f3f3;
  @media (max-width: 748px) {
    width: 89%;
  }
`;

export const FooterActions = styled.div`
  display: flex;
  flex: 1;
  justify-content: flex-end;
  > img {
    height: 30px;
    cursor: pointer;
  }
`;

export const SendButton = styled.button`
  text-decoration: none;
  text-transform: capitalize;
  font-weight: 500;
  color: #fff;
  vertical-align: top;
  font-family: Avenir;
  background-color: #45d18b;
  border: none;
  padding: 15px;
  font-size: 1em;
  width: 100%;
  cursor: pointer;
  border-radius: 4px;
  &:hover {
    opacity: 0.9;
  }
`;
