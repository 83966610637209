import React from 'react';
import axios from 'axios';
import PropTypes from 'prop-types';
import FacebookLogin from 'react-facebook-login/dist/facebook-login-render-props';
import { FacebookLoginButton, FacebookLoginButtonBig, SuperScript } from '../shared/global-styles';
import { BASE_URL } from '../../constants/enums';

export const CHECK_LOGGED_IN_STATUS = () => {
  const token = localStorage.getItem('authToken');
  if (!token) return window.location.assign('/');
  const userMeta = localStorage.getItem('userMeta');
  const user = JSON.parse(userMeta);
  if (!user.verified) return window.location.assign(`/complete-onboarding/${user.id}`);
  return null;
};

export const UPDATE_IS_VERIFIED = (user) => {
  user.verified = true;
  localStorage.setItem('userMeta', JSON.stringify(user));
  localStorage.setItem('previouslyLoggedIn', true);
};

export const LOGOUT = () => {
  localStorage.setItem('previouslyLoggedIn', true);
  localStorage.removeItem('authToken');
  localStorage.removeItem('userMeta');
  sessionStorage.removeItem('loadedMatches');
  sessionStorage.removeItem('offsetCount');
  localStorage.removeItem('isGenderVerified');
  window.location.assign('/');
};

export const authenticate = async (authData) => {
  // eslint-disable-next-line no-console
  try {
    // this.setState({ progressMessage: 'Authenticating, please wait...', loginErrorMessage: '' });
    const response = await axios.post(`${BASE_URL}/auth/login`, authData);
    // eslint-disable-next-line no-console
    if (response.status === 'unknown') {
      window.location.reload();
    }

    if (response.status === 200) {
      const { data } = response;
      const { authToken, basic } = data;
      if (authToken && basic) {
        // this.setState({ progressMessage: 'Preparing account...', loginErrorMessage: '' });
        localStorage.setItem('authToken', JSON.stringify(authToken));
        localStorage.setItem('userMeta', JSON.stringify(basic));

        if (!basic.verified) {
          window.location.assign(`/verify-account/${basic.email}`);
        } else {
          // NAVIGATE TO BROWSE
          window.location.assign('/browse');
        }
      }
    }
  } catch (err) {
    // stuff here
    // this.setState({ loginErrorMessage: `${err}, try again!`, progressMessage: '' });
    // eslint-disable-next-line no-console
    window.location.reload();
  }
  return null;
};

export const magicLogin = async (authData) => {
  // eslint-disable-next-line no-console
  try {
    const response = await axios.post(`${BASE_URL}/auth/magicLink`, authData);
    // eslint-disable-next-line no-console
    if (response.status === 'unknown') {
      window.location.reload();
    }

    if (response.status === 200) {
      return response.data;
    }
  } catch (err) {
    // eslint-disable-next-line no-console
    window.location.reload();
  }
  return null;
};

export const saveCookiesOnSuccessfulLogin = async (authData) => {
  const { authToken, basic } = authData;
  if (authToken && basic) {
    localStorage.setItem('authToken', JSON.stringify(authToken));
    localStorage.setItem('userMeta', JSON.stringify(basic));
    return;
  }
};

const Authentication = ({ size }) => {
  const stuff = '';
  return null;
};

Authentication.propTypes = {
  size: PropTypes.string.isRequired,
};

export default Authentication;
