import styled from 'styled-components';
import { Link } from 'react-router-dom';

export const ProfileViewsContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  flex-direction: row;
  flex-wrap: wrap;
  @media(max-width: 414px) {
    justify-content: space-between;
  }
`;

export const SectionTitle = styled.h3`
  font-family: Avenir;
  font-size: 1em;
  margin-bottom: 1em;
  color: #333;
`;

export const VisitorWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  margin-right: 20px;
`;

export const AvatarContainer = styled.div`
  width: 100px;
  height: 100px;
  overflow: hidden;
  border-radius: 50px;
  @media(max-width: 375px) {
    width: 125px;
    height: 125px;
    border-radius: 62.5px;
  }
`;

export const VisitorAvatar = styled.img`
  object-fit: contain;
  width: 100%;
`;

export const VisitorName = styled.p`
  font-family: Avenir;
  font-size: 0.9em;
  font-weight: 500;
  text-align: center;
  margin-top: 0.5em;
  opacity: 0.6;
  color: #333;
`;

export const CustomLink = styled(Link)`
  text-decoration: none;
  &:hover {
    opacity: 0.7;
  }
`;
