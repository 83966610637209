import styled from 'styled-components';

export const LocationWrapper = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  padding: 10px;
  margin: 20px auto 40px auto;
  @media(min-width: 414px) {
    margin: 40px auto;
    padding: 0;
  }
`;

export const GooglePlacesInput = styled.input`
  font-size: 16.4px;
  width: 300px;
  padding: 0 8px;
  margin: 1em auto 0 auto;
  box-sizing: border-box;
  cursor: auto;
  font-family: Avenir;
  border: none;
  height: 44px;
  @media(max-width: 414px) {
    margin: 1em auto 0 auto;
  }
`;

export const DropdownContainer = styled.div`
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  border-radius: 0 0 2px 2px;
  width: 300px;
  margin: 0 auto;
  cursor: pointer;
`;

export const LoadingWrapper = styled.div`
  width: 80%;
  background-color: #fafafa;
  margin: 0 auto;
`;

export const SuggestionsItem = styled.div`
  padding: 5px;
  font-size: 0.9em;
  font-weight: 700;
  font-family: avenir;
  background-color: #fafafa;
  &:hover {
    background-color: #fff;
  }
`;

export const Location = styled.h3`
  color: #fff;
  text-align: center;
  width: 60%;
  margin: 14px auto 0;
  @media(max-width: 414px) {
    width: 90%;
  }
`;

export const LocationTitle = styled.p`
  color: #fff;
  text-align: center;
  line-height: 0;
  margin-bottom: 0;
`;

// Set Button BG Color
const setBtnColor = (action) => {
  if (action === 'confirm') return '#2abb53';
  if (action === 'cancel') return '#333';
  return '#333';
};

export const Button = styled.button`
  padding: 0.6em;
  width: 200px;
  color: #fff;
  font-size: 1em;
  background: ${props => setBtnColor(props.action)};
  margin: 1em auto 0;
  cursor: pointer;
  border: none;
  font-family: Avenir;
  font-weight: 500;
`;