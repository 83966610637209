import * as React from 'react';
import { Formik } from 'formik';
import {
  FormControl,
  FormLabel,
  FormErrorMessage,
  FormHelperText,
  Input,
  Progress,
  Text,
  Heading,
  Box,
  Button,
} from '@chakra-ui/react';
import { useParams, useNavigate } from 'react-router-dom';
// import { ArrowForwardIcon } from '@chakra-ui/icons';

/* Constants */
import { BASE_URL } from 'constants/enums';

/**
 * Style imports
 */
import {
  SendButton,
  LoginFormContainer,
  SubTitle,
  FormTitle,
  FormField,
  Disclaimer,
} from './styles';

/* Auth */
import { authenticate, magicLogin } from 'app/auth/authentication';

// login
const LoginForm = ({ submitting, toggleForm, setSubmitting }) => {
  const navigate = useNavigate();

  /**
   * States
   */
  const [checkInbox, setCheckInbox] = React.useState(false);
  const [userEmail, setUserEmail] = React.useState('');

  /**
   *
   * Send verification email
   */
  const onUserLogin = async (user) => {
    const { email } = user;
    if (email !== '') {
      setUserEmail(email);
      setSubmitting(true);
      const res = await magicLogin(user);
      if (res.success) {
        navigate(`/verify-account/${email}`);
        setCheckInbox(true);
      }
      setSubmitting(false);
    }
  };

  const [input, setInput] = React.useState('afd');

  if (!checkInbox) {
    return (
      <Formik
        initialValues={{
          email: '',
        }}
        onSubmit={(values) => {
          const { email } = values;
          onUserLogin({
            email,
          });
        }}
      >
        {({ values, setFieldValue, handleChange }) => (
          <LoginFormContainer>
            <Box>
              <Text fontSize="sm" align="center" mb={0} opacity={0.6} fontWeight={500}>
                Register or Log in to Sevn Dates
              </Text>
              <FormTitle textAlign="center" color="#c23c78">
                Matches await!
              </FormTitle>
            </Box>
            <Box mb={5}>
              <Input
                type="email"
                id="email"
                name="email"
                value={values.email} // or whatever the value is
                onChange={handleChange}
                placeholder="Enter email address"
                isRequired
                err
                height={54}
                bg={'#eee'}
                textAlign="center"
                fontWeight={500}
                color="#73399b"
              />
              <SendButton
                type="submit"
                onClick={() => onUserLogin(values)}
                isDisabled={values.email === ''}
                // opacity={values.email === ''}
              >
                {submitting ? 'Authenticating...' : 'Confirm Email'}
              </SendButton>
              <Disclaimer textAlign="center">
                By signing up, you confirm that you have read and agreed to our{' '}
                <a href="#">Terms of Use</a> & <a href="#">Privacy Policy</a>.
              </Disclaimer>
            </Box>
          </LoginFormContainer>
        )}
      </Formik>
    );
  }
};

export default LoginForm;
